import {
	routeLogin,
	routeSignIn,
	routeCodeSignIn,
	routePlayer,
	routeCreateAccount,
	routeTitleDetails,
	routeVamPlayer,
	routeTrailer,
	routeStyledComponentTest,
	routeVinfastComponents,
	routeDevLoginScreen,
	routeNewSettings,
	routeRecoverPassword,
	routeDataManagementSettings,
	routeRedeemVoucher,
	routeScreenManager,
	routeHome,
	routeMyLibrary,
	routeProfileNameSelect,
	routeSearchScreen,
	routeNewCuration,
	routeVamScreenManager,
	routeAggregatePage,
	routeVamAggregatePage,
	routePayment,
	routePage,
	routePreview,
	routeDataManagementSettingsDetails,
	routeCreateAccountDetails,
	routePrivacyPolicy,
	routeLegalsScreen,
	routeMobilePayment
} from "../Routes";

const config = {
	screens: {
		[routePreview]: {
			path: "",
		},
		[routeLogin]: {
			path: "landing",
		},
		[routeSignIn]: {
			path: "signin/",
		},
		[routeCreateAccount]: {
			path: "createaccount/:location",
		},
		[routeCreateAccountDetails]: {
			path: "createaccount/:location/:subLocation",
		},
		[routeHome]: {
			path: "home/",
		},
		[routeTitleDetails]: {
			path: "metadata/:parentProductId",
		},
		[routePlayer]: {
			path: "player/:parentProductId/:resumeProgress",
		},
		[routeVamPlayer]: {
			path: "vam/:parentProductId/:vamId/:location/:redirectTo",
		},
		[routeTrailer]: {
			path: "player/:parentProductId/trailer/",
		},
		[routeStyledComponentTest]: {
			path: "styledcomponents",
		},
		[routeVinfastComponents]: {
			path: "vinfaststyleguide",
		},
		[routeDevLoginScreen]: {
			path: "devLoginScreen",
		},
		[routeRecoverPassword]: {
			path: "recoverpassword/:settings",
		},
		[routeNewSettings]: {
			path: "settings",
		},
		[routeDataManagementSettings]: {
			path: "settingsdatamanagement/:location",
		},
		[routeDataManagementSettingsDetails]: {
			path: "settingsdatamanagement/:location/:subLocation",
		},
		[routeRedeemVoucher]: {
			path: "redeemvoucher",
		},
		[routeScreenManager]: {
			path: "screenmanager/:location/:parentProductId/:redirectTo",
		},
		[routeVamScreenManager]: {
			path: "screenmanager/:location/:parentProductId/:vamId/:redirectTo/:customListId?",
		},
		[routeMyLibrary]: {
			path: "mylibrary",
		},
		[routeProfileNameSelect]: {
			path: "devicename/:location",
		},
		[routeSearchScreen]: {
			path: "search",
		},
		[routeNewCuration]: {
			path: "shortclips",
		},
		[routeAggregatePage]: {
			path: "aggregatepage/:location?/:id/",
		},
		[routeVamAggregatePage]: {
			path: "aggregatepage/:customListId/vam",
		},
		[routePayment]: {
			path: "payment/:parentProductId/:paymentType",
		},
		[routePage]: {
			path: "page/:uniqueId",
		},
		[routeCodeSignIn]: {
			path: "code-signin"
		},
		[routePrivacyPolicy]: {
			path: "privacy-policy"
		},
		[routeLegalsScreen]: {
			path: "legals-screen/:location",
		},
		[routeMobilePayment]: {
			path: "mobile-payment/:parentProductId/:paymentType",
		}
	},
};

const navLinking = {
	prefixes: [],
	config,
};

export default navLinking;
