import styled, { css } from "styled-components/native";
import ControllerContainer from "../../components/Layout/ControllerContainer";
import { View } from "react-native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import Image from "../../components/Image/Image";

interface Props {
	data?: any;
	isTitle?: boolean;
	isShortClips?: boolean;
	ribbonTitle?: string;
	isNotRedeem?: boolean;
	onPress: () => void;
}

const StyledController = styled.View<Props>`
	display: flex;

	${(props) =>
		props.isTitle &&
		css`
			justify-content: center;
			align-items: center;
			height: 100%;
		`}

	${(props) =>
		props.isShortClips &&
		props.isTitle &&
		css`
			width: 94vw;
		`}

	${(props) =>
		!props.isTitle &&
		css`
			flex-direction: row;
			margin-left: 120px;
		`}

	${(props) =>
		props.isShortClips &&
		css`
			margin-left: 150px;
		`}
`;

const StyledControllerButton = styled.View`
	display: flex;
`;

const StyledControllerText = styled.View`
	text-align: center;
	justify-content: center;
	margin-left: 20px;
`;

const StyledText = styled.Text`
	font-size: 25px;
	color: white;
`;

const StyledControllerTextContainer = styled.View<Props>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	${(props) =>
		props.isShortClips &&
		css`
			padding-right: 6.5%;
		`}
`;

const StyledButtonContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding:0 1rem;
`;

const ControllerContent = (props: Props) => {
	return (
		<View>
			{/* @ts-ignore */}
			<ControllerContainer>
				{/* @ts-ignore */}
				<StyledController isTitle={props.isTitle} isShortClips={props.isShortClips}>
					{!props.isTitle && (
						<>
							{!props.isNotRedeem && (
								/* @ts-ignore */
								<StyledControllerButton>
									<TouchableButton
										componentTheme={ComponentThemeType.VinFast}
										type={ComponentTypeEnum.Secondary}
										onPress={props?.onPress}
									>
										{props?.data?.icon && (
											// @ts-ignores
											<StyledButtonContainer>
												<Image source={{ uri: props?.data?.icon }} width={56} height={56}></Image>
												{/* @ts-ignores */}
												<StyledText>{props?.data?.buttonText}</StyledText>
											</StyledButtonContainer>
										)}
										{!props?.data?.icon && props?.data?.buttonText}
									</TouchableButton>
								</StyledControllerButton>
							)}
							{/* @ts-ignores */}
							<StyledControllerText>
								{/* @ts-ignores */}
								<StyledText>{props?.data?.textDescription}</StyledText>
							</StyledControllerText>
						</>
					)}
					{props.isTitle && (
						// @ts-ignores
						<StyledControllerTextContainer isShortClips={props.isShortClips}>
							{/* @ts-ignores */}
							<StyledControllerText>
								{/* @ts-ignores */}
								<StyledText>{props?.ribbonTitle}</StyledText>
							</StyledControllerText>
						</StyledControllerTextContainer>
					)}
				</StyledController>
			</ControllerContainer>
		</View>
	);
};

export default ControllerContent;
