import { CheckEnrollmentRequest } from "../models/request/CheckEnrollmentRequest";
import { PaymentAuthorizationRequest } from "../models/request/PaymentAuthorizationRequest";
import { SetupRequest } from "../models/request/SetupRequest";
import { CheckEnrollmentResponse } from "../models/response/CheckEnrollmentResponse";
import { PaymentAuthorizationResponse } from "../models/response/PaymentAuthorizationResponse";
import { SetupResponse } from "../models/response/SetupResponse";
import { TaxAddress } from "../models/TaxAddress";
import { PAYMENT_BASEURL_V6, PAYMENT_BASEURL_V8, QR_CONNECT_V1 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

export const purchaseHistory = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "purchase/history", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

export const productPrice = async (parentProductId: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "product/" + parentProductId + "?SupportedCodecs=1%2C2", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

export const countryList = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "avalara/countries", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

export const regionListByCountry = async (countryCode: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "avalara/regions?countryCode=" + countryCode, { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

/**
 * Tax calculation
 * @param transactionId - string transaction Id
 * @param taxRequest - model tax Address request body
 * @returns - return calculated tax
 */
export const tax = async (transactionId: string, taxRequest: TaxAddress) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "avalara/" + transactionId + "/tax", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(taxRequest),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Validation of address using Avalara
 * @param address - model address information request body
 * @returns - return valid address
 */
export const resolveAddress = async (address: TaxAddress) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V6 + "avalara/resolveaddress", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(address),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * 3DS: Start the 3DS process.
 * @param transactionId - transactionId of the transaction
 * @param request - setup request for 3DS. start after the user input the card details.
 * @returns SetupResponse
 */
export const setup = (transactionId: string, request: SetupRequest): Promise<SetupResponse> => {
	let promise = new Promise<SetupResponse>((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "auth/" + transactionId + "/setup", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(request),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * 3DS: Check the card if enrolled to the authentication program. Use only when DDC is completed on the client side.
 * @param transactionId - transactionId of the transaction
 * @param request - check enrollment request for 3DS. start only when DDC is completed
 * @returns CheckEnrollmentResponse
 */
export const checkEnrollment = (transactionId: string, request: CheckEnrollmentRequest): Promise<CheckEnrollmentResponse> => {
	let promise = new Promise<CheckEnrollmentResponse>((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "auth/" + transactionId + "/check", {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(request),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Perform payment authorization
 * @param transactionId - transactionId of the transaction
 * @param request - payment authorization request
 * @returns PaymentAuthorizationResponse
 */
export const paymentAuthorization = async (transactionId: string, request: PaymentAuthorizationRequest): Promise<PaymentAuthorizationResponse> => {
	let promise = new Promise<PaymentAuthorizationResponse>((resolve, reject) => {
		fetchWrapper(PAYMENT_BASEURL_V8 + "auth/" + transactionId, {
			method: "POST",
			headers: getHeaders(true),
			body: JSON.stringify(request),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

export const qrConnect = async (transactionId: string) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(QR_CONNECT_V1 + transactionId + "/status", {
			method: "GET",
			headers: getHeaders(true),
		})
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};
