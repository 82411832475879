import { royal_blue_hex } from "../../StyleHelpers";
import { ActivityIndicator, ProgressBar } from "react-native-paper";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import styled, { css } from "styled-components/native";
interface Props {
	title: string;
	hideButton?: boolean;
	counter: number;
	disabled: boolean;
	isMobile: boolean;
	onPress?: () => void;
}

const StyledContainer = styled.View`
	display: flex;
	height: 160px;
	padding: 0px 67px;
	justify-content: center;
	align-items: center;
	border-radius: 16px;
	background: ${royal_blue_hex};
	margin-bottom: 30px;
`;

const StyledText = styled.Text<Props>`
	color: #fff;
	${(props) =>
		props.disabled &&
		css`
			opacity: 0.5;
		`}

	font-size: 32px;
	font-style: normal;
	line-height: 28px;
`;

const StyledCodeWrapper = styled.View<Props>`
	display: flex;
	width: 344px;
	padding: 66px 22px 16px 22px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	gap: 34px;
	flex-shrink: 0;
	border-radius: 16px;
	margin-bottom: 30px;
	background: rgba(255, 255, 255, 0.2);
	${(props) =>
		props.isMobile &&
		css`
			background: transparent;
			align-items: "";
			padding: 66px 22px 16px 0px;
		`}
`;

const StyledCodeText = styled.Text`
	color: #fff;
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 56% */
	letter-spacing: 2px;
`;

const StyledSpinnerWrapper = styled.View`
	margin-top: -20px;
`;

const progressBarSize = (isMobile: boolean) => {
	if (isMobile) {
		return { borderRadius: 4, height: " 0.31738rem", width: "16.875rem", backgroundColor: "#002E11" };
	}
	return { borderRadius: 4, height: 16, width: 300, backgroundColor: "#002E11" };
};

const GradientCodeComponent = (props: Props) => {
	const counter = parseFloat(props.counter.toString()).toFixed(2);

	return (
		<>
			{!props?.hideButton ? (
				<TouchableOpacity onPress={props.onPress} disabled={props.disabled}>
					{/* @ts-ignores */}
					<StyledContainer>
						{/* @ts-ignores */}
						<StyledText disabled={props.disabled}>{props.title}</StyledText>
					</StyledContainer>
				</TouchableOpacity>
			) : (
				// @ts-ignores
				<StyledCodeWrapper isMobile={props.isMobile}>
					{!props.title ? (
						// @ts-ignores
						<StyledSpinnerWrapper>
							<ActivityIndicator size={"large"} color={"white"} />
						</StyledSpinnerWrapper>
					) : (
						<></>
					)}
					{/* @ts-ignores */}
					{props.title ? <StyledCodeText>{props.title}</StyledCodeText> : <></>}
					<ProgressBar progress={parseFloat(counter) ?? 0} color={"#008131"} style={progressBarSize(props?.isMobile)} />
				</StyledCodeWrapper>
			)}
		</>
	);
};

export default GradientCodeComponent;
