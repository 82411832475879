import { SafeAreaView } from "react-native";
import TopAppBar from "../../components/TopAppBar";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import styled from "styled-components/native";
import { useEffect, useState } from "react";
import { Metadata } from "../../models/Metadata";
import useMovieContentStore from "../../store/useMovieContent.store";
import { capitalize, globalAny, metadataTextSeparator } from "../../utils/Utils";
import usePaymentTransactionStore from "../../store/usePaymentTransaction.store";
import { useNavigation } from "@react-navigation/native";
import { routeTitleDetails, TitleDetailsScreenNavigationProp } from "../../Routes";
import Image from "../../components/Image/Image";
import { AsyncStorageKeys, PurchaseTransactionType } from "../../Types";
import { preverify } from "../../services/purchaseService";
import { PaymentGateway } from "../../enums/PaymentGateway";
import { PurchaseProductType } from "../../enums/PurchaseProductType";
import { ProductTierDetails } from "../../models/ProductTierDetails";
import { productPrice, qrConnect } from "../../services/paymentService";
import GradientCodeComponent from "../../components/GenerateCodeComponent";
import { TOTP } from "totp-generator";
import TouchableButton from "../../components/TouchableButton";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import ComponentThemeType from "../../models/ComponentTheme";
import Toast from "../../components/Toast";
import ProcessStatuses from "../../models/ProcessStatuses";
import useGenericContentStore from "../../store/genericContent.store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AppLogger } from "../../utils/AppLogger";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import ENV_CONFIG from "../../config/ENV_CONFIG";
import QRCode from "react-qr-code";

const StyledMain = styled.View`
	background-color: rgb(20, 20, 20);
	justify-content: center;
	display: flex;
	align-items: center;
	height: 100%;
`;

const StyledContainer = styled.View`
	display: flex;
	width: 80%;
	height: 62.5%;
	flex-direction: column;
	gap: 6.3125rem;
`;

const StyledDetails = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
`;

const StyledBox = styled.View`
	display: flex;
	width: 16.875rem;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	z-index: 9999;
`;
const StlyedText = styled.Text`
	overflow: hidden;
	color: #f2f2f2;
	text-overflow: ellipsis;
	font-family: Nunito;
	font-size: 3.125rem;
	font-style: normal;
	font-weight: 500;
	line-height: 3.75rem;
`;
const StlyedSubText = styled.Text`
	color: #f2f2f2;
	font-family: Nunito;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 300;
	line-height: 1.25rem;
`;

const StyledPackshot = styled.View`
	width: 16.875rem;
	height: 25.25rem;
	margin-bottom: 1.5rem;
	flex-shrink: 0;
`;

const StyledQR = styled.View`
	width: 16.875rem;
	height: 16.5rem;
	margin-top: 1.5rem;
	background-color: #fff;
	border-radius: 8px;
`;

const StyledDeviceBox = styled.View`
	display: flex;
	height: 31.5rem;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	z-index: 9999;
`;

const StyledButtonContainer = styled.View`
	display: flex;
	width: 23rem;
	top: 21.5rem;
	justify-content: center;
	gap: 0.625rem;
`;
let pollingInterval: any;
const paymentGateway: PaymentGateway = PaymentGateway.CyberSource;
const purchaseProductType: PurchaseProductType = PurchaseProductType.ParentProduct;

const MobilePaymentScreen = (props: any) => {
	const { route } = props;
	const [metadata, setMetadata] = useState<Metadata>();
	const movieStoreValue = useMovieContentStore((state) => state.data);
	const { parentProductId, paymentType } = route.params;
	const currencySymbol = usePaymentTransactionStore((state: any) => state.currencySymbol);
	const purchasePrice = usePaymentTransactionStore((state: any) => state.purchasePrice);
	const setIsSuccess = useGenericContentStore((state: any) => state.setIsSuccess);
	const rentPrice = usePaymentTransactionStore((state: any) => state.rentPrice);
	const fetchTitleDetails = useMovieContentStore((state) => state.fetchTitleDetails);
	const navigationTitleDetails = useNavigation<TitleDetailsScreenNavigationProp>();
	const [packshot, setPackshot] = useState();
	const [isDeviceConnected, setIsDeviceConnected] = useState(false);
	const [ratingReason, setRatingReason] = useState("");
	const [counter, setCounter] = useState(0.822222);
	const [timer, setTimer] = useState(false);
	const [generatedOTP, setGeneratedOTP] = useState("");
	const [decodedKey, setDecodedKey] = useState("");
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [type, setType] = useState(ComponentTypeEnum.Secondary);
	const [transactionId, setTransactionId] = useState("");
	const [showQR, setShowQR] = useState(false);
	const [qrCode, setQRCode] = useState("");
	const RENT = "Rent";

	const getTitleBody = () => {
		const currentPaymentType = capitalize(paymentType);
		const isRent = currentPaymentType === RENT;
		const word = globalAny.language.tax_apply
			.replace("%d", paymentType === RENT.toLowerCase() ? globalAny.language.rent : globalAny.language.purchase_word)
			.replace("%p", `${currencySymbol}${isRent ? rentPrice : purchasePrice}`);
		return word;
	};

	const back = () => {
		setShowCancelModal(true);
	};

	const confirmBackButton = () => {
		transactionError();
		navigationTitleDetails.navigate(routeTitleDetails, { parentProductId });
	};

	const getPaymentType = () => {
		return capitalize(paymentType) === RENT ? PurchaseTransactionType.Rent : PurchaseTransactionType.Purchase;
	};

	const initTransactionDetails = async () => {
		try {
			fetchTitleDetails(parentProductId, true);
			const prices: any = await productPrice(parentProductId);

			const [purchaseTypePrice] = prices.productTierDetails.filter((product: any) => product.transactionTypeId === getPaymentType());

			const preverifyResponse = await preverify(parentProductId, paymentGateway, purchaseProductType, purchaseTypePrice as ProductTierDetails);
			const decodedString = atob(preverifyResponse?.totpKey);
			const byteArray = [];
			const email = encodeURIComponent(localStorage.getItem(AsyncStorageKeys.email) ?? "");
			const language = localStorage.getItem(AsyncStorageKeys.LanguageOverride);
			const apiKey = localStorage.getItem(AsyncStorageKeys.apiKey);

			setTransactionId(preverifyResponse?.transactionId);

			for (let i = 0; i < decodedString.length; i++) {
				byteArray.push(decodedString.charCodeAt(i));
			}

			const base32 = require("hi-base32");
			let decodedKey = base32.encode(byteArray);
			setDecodedKey(decodedKey);

			const { otp } = TOTP.generate(decodedKey);
			const generatedCode = otp.slice(0, 3) + "-" + otp.slice(3);
			setGeneratedOTP(generatedCode);
			setQRCode(
				`${ENV_CONFIG.MOBILE_PAYMENT_URL}/${
					preverifyResponse?.transactionId
				}?email=${email}&language=${language}&apiKey=${apiKey}&transactionTypeId=${getPaymentType()}`,
			);
			setShowQR(true);
			setTimer(true);
		} catch (error) {
			transactionError();
		}
	};

	const transactionError = () => {
		startCountdownAndHandleError();
		setToastMessage(globalAny.language.error_validation);
		setType(ComponentTypeEnum.Error);
		setVisible(true);
	};

	const startCountdownAndHandleError = async () => {
		try {
			const currentTime = new Date().getTime();
			const waitingPeriod = 15 * 1000;
			const newAllowUntil = currentTime + waitingPeriod;
			await AsyncStorage.setItem(AsyncStorageKeys.allowUntil, newAllowUntil.toString());
		} catch (error) {
			AppLogger.error(error);
		}
	};

	const onDismissSnackBar = () => {
		navigationTitleDetails.navigate(routeTitleDetails, { parentProductId });
	};

	const onPaymentCompleted = () => {
		setIsSuccess(true, getPaymentType() === 3);
		navigationTitleDetails.navigate(routeTitleDetails, { parentProductId });
	};

	const verifyQR = (qrResponse: any) => {
		if (qrResponse.responseCode !== 10000) {
			transactionError();
			return;
		}
		switch (qrResponse.processStatus) {
			case ProcessStatuses.Processing:
				setIsDeviceConnected(true);
				break;
			case ProcessStatuses.Cancelled:
			case ProcessStatuses.Failed:
				clearInterval(pollingInterval);
				startCountdownAndHandleError();
				setToastMessage(globalAny.language.payment_transaction_cancel_text);
				setType(ComponentTypeEnum.Processing);
				setVisible(true);
				break;
			case ProcessStatuses.Completed:
				clearInterval(pollingInterval);
				onPaymentCompleted();
				break;
		}
	};

	useEffect(() => {
		if (transactionId) {
			(async () => {
				const qrResponse = await qrConnect(transactionId);

				verifyQR(qrResponse);

				pollingInterval = setInterval(async () => {
					const qrResponse = await qrConnect(transactionId);
					verifyQR(qrResponse);
				}, 5000);
			})();
		}
		return () => clearInterval(pollingInterval);
	}, [transactionId]);

	useEffect(() => {
		if (!timer) return;
		if (counter > 0 && timer) {
			setTimeout(() => {
				setCounter(counter - 0.02666666666);
			}, 1000);
		} else {
			setCounter(0.822222);
			setTimer(true);
			const { otp } = TOTP.generate(decodedKey);
			const generatedCode = otp.slice(0, 3) + "-" + otp.slice(3);
			setGeneratedOTP(generatedCode);
		}
	}, [counter, timer]);

	useEffect(() => {
		(async () => {
			const result = movieStoreValue.titleDetails.titles[parentProductId];
			if (result?.metadata) {
				setMetadata(result?.metadata);
				const packshotImage = result?.metadata.images
					?.filter((val: any) => val.width === 600 && val.height === 900)
					?.map((val: any) => val.url)[0];
				setPackshot(packshotImage);
				setRatingReason(metadataTextSeparator(result.metadata));
			}
		})();
	}, [route.params, movieStoreValue]);

	useEffect(() => {
		initTransactionDetails();
	}, []);

	const renderGenerateCode = () => {
		return (
			/* @ts-ignores */
			<StyledDetails>
				{/* @ts-ignores */}
				<StyledBox>
					{/* @ts-ignores */}
					<StlyedText>{globalAny.language.purchase_scan_title}</StlyedText>
					{/* @ts-ignores */}
					<StlyedSubText>{globalAny.language.purchase_scan_qr_code}</StlyedSubText>
					{showQR && (
						/* @ts-ignores */
						<StyledQR>
							{/* @ts-ignores */}
							<QRCode
								size={128}
								style={{ height: "100%", width: "100%", marginTop: 15, marginBottom: 15 }}
								value={qrCode}
								viewBox={`0 0 256 256`}
							/>
							{/* <Image source={{ uri: QR }} width={"100%"} height={"100%"} resizeMode="contain" /> */}
						</StyledQR>
					)}
				</StyledBox>
				{/* @ts-ignores */}
				<StyledBox>
					{/* @ts-ignores */}
					<StlyedText>{globalAny.language.purchase_enter_code}</StlyedText>
					{/* @ts-ignores */}
					<StlyedSubText>{globalAny.language.purchase_enter_code_phone}</StlyedSubText>
					<GradientCodeComponent title={generatedOTP} hideButton={true} counter={counter} disabled={true} isMobile={true} />
					{/* @ts-ignores */}
					<StlyedSubText>{globalAny.language.purchase_completed}</StlyedSubText>
				</StyledBox>
				{/* @ts-ignores */}
				<StyledBox>
					{/* @ts-ignores */}
					<StyledPackshot>
						<Image source={{ uri: packshot }} width={"100%"} height={"100%"} style={{ borderRadius: 16, position: "absolute" }} />
					</StyledPackshot>
					{/* @ts-ignores */}
					<StlyedSubText>{ratingReason}</StlyedSubText>
				</StyledBox>
			</StyledDetails>
		);
	};

	const renderDeviceConnected = () => {
		return (
			/* @ts-ignores */
			<StyledDetails>
				{/* @ts-ignores */}
				<StyledDeviceBox>
					{/* @ts-ignores */}
					<StlyedText>{globalAny.language.transaction_title}</StlyedText>
					{/* @ts-ignores */}
					<StlyedSubText>{globalAny.language.purchase_device_detail}</StlyedSubText>
					{/* @ts-ignores */}
					<StyledButtonContainer>
						<TouchableButton type={ComponentTypeEnum.Primary} componentTheme={ComponentThemeType.VinFast} onPress={back}>
							{globalAny.language.exit}
						</TouchableButton>
					</StyledButtonContainer>
				</StyledDeviceBox>

				{/* @ts-ignores */}
				<StyledBox>
					{/* @ts-ignores */}
					<StyledPackshot>
						<Image source={{ uri: packshot }} width={"100%"} height={"100%"} style={{ borderRadius: 16, position: "absolute" }} />
					</StyledPackshot>
					{/* @ts-ignores */}
					<StlyedSubText>{ratingReason}</StlyedSubText>
				</StyledBox>
			</StyledDetails>
		);
	};

	return (
		<SafeAreaView style={{ flex: 1 }}>
			{/* @ts-ignores */}
			<StyledMain>
				<TopAppBar
					leftTitleIcon={backIcon}
					leftIconWidth={92}
					screenTitle={metadata?.title}
					screenTitleBody={getTitleBody()}
					onPressLeftTitleIcon={back}
				/>
				{/* @ts-ignores */}
				<StyledContainer>{isDeviceConnected ? renderDeviceConnected() : renderGenerateCode()}</StyledContainer>
			</StyledMain>
			<Toast visible={visible} text={toastMessage} type={type} onDismissSnackBar={onDismissSnackBar} duration={5000} />
			{showCancelModal && (
				<CustomizeDialogBox
					Header={
						capitalize(paymentType) === RENT ? globalAny.language.cancel_rent_transaction : globalAny.language.cancel_purchase_transaction
					}
					Body={globalAny.language.validating_cancel_transaction}
					ButtonTextRight={globalAny.language.cancel}
					ButtonTextLeft={globalAny.language.ok.toUpperCase()}
					SingleButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Primary}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					onPressRight={() => setShowCancelModal(false)}
					onPressLeft={() => {
						startCountdownAndHandleError();
						confirmBackButton();
					}}
				/>
			)}
		</SafeAreaView>
	);
};

export default MobilePaymentScreen;
