import "shaka-player/dist/controls.css";
import "../../player/bcore-theme.css";
import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { PRODUCT_TRACK_FILE_TYPES, TRANSACTION_TYPES } from "../../networking/networking";
import { getVamDetails, getVamPlayerDetails } from "../../services/vamService";
import { getMetadata, getOwnedStatus } from "../../services/metadataService";
import {
	endProgress,
	getDateTimePlusHour,
	getDeviceInfo,
	getLanguageName,
	getVideoQualityByDeviceHeight,
	globalAny,
	inWatchGroup,
	isMaster,
} from "../../utils/Utils";
import {
	routeScreenManager,
	routeVamScreenManager,
	routeHome,
	routeNewCuration,
	routeLogin,
	ScreenManagerNavigationProp,
	VamScreenManagerNavigationProp,
	routeVamAggregatePage,
	routeTitleDetails,
} from "../../Routes";
import { AsyncStorageKeys, CarActivity, Pages, ProfileName, ProgressEvents, direction } from "../../Types";
import { MqttCommand } from "../../models/mqtt-command";
import { setProgressVideo } from "../../services/progressVideoService";
import { AppLogger } from "../../utils/AppLogger";
import { getDubCards, getAvailableAudioLanguages, getVideo } from "../../services/videoService";
import { shakaPlayColor } from "../../StyleHelpers";
import CardPackshot from "../../components/CardPackshot";
import styled from "styled-components/native";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import useGenericContentStore from "../../store/genericContent.store";
import Toast from "../../components/Toast";
import PlayerLoader from "../../components/Loaders/PlayerLoader";
import mitt from "mitt";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useMovieContentStore from "../../store/useMovieContent.store";
import useDrivingStore from "../../store/useDriving.store";
import useMqttStore from "../../store/useMqtt.store";
import MqttSenderService from "../../services/mqtt/MqttSenderService";
import MqttReceiverService from "../../services/mqtt/MqttReceiverService";
import Nerds from "../../components/Modal/nerds";
import NerdButton from "../../components/NerdButton";
import { v4 as uuidv4 } from "uuid";
import PlayerFiles from "../../models/PlayerFiles";
import useToggleStore from "../../store/useToggle.store";

const StyledContainer = styled.View`
	margin-top: 112px;
	position: fixed;
	width: 100%;
	background-color: transparent;
	height: calc(100% - 224px);
	flex: 1;
	justify-content: center;
	flex-direction: row;
`;

const PackshotContainer = styled.View`
	justify-content: center;
	align-items: center;
	margin: auto;
	width: 22%;
`;

let LazyPlayer = React.lazy(() => import("../../player/player"));

let screenHost: any = [];
let progressInterval: any;
let isStop = false;
let dubCards: any = [];
let cancelPreRoll: boolean = false;
let preRollCollection: any = [];
let preRollUrl: string = "";
let skipLess: boolean = false;

const PlayerEventHandlerExternal = mitt();
//@ts-ignore
window.mqttPlayContent = null;
//@ts-ignore
window.lastKnownLeaderPosition = 0;
//@ts-ignore
window.timeSinceLastKnownLeaderPosition = Date.now();
//@ts-ignore
window.lastKnownLatency = 0;
//@ts-ignore
window.pingSent = 0;
//@ts-ignore
window.pingBackReceived = 0;
//@ts-ignore
window.ignoreCommands = false;
//@ts-ignore
window.isBuffering = false;
//@ts-ignore
window.connectionTimeoutTimer;

let groupSyncColor: any = shakaPlayColor;

const PlayerScreen = (props: any) => {
	const navigationVamScreenManager = useNavigation<VamScreenManagerNavigationProp>();
	const { parentProductId, streamUrl, refresh, vamId, resumeProgress, location, redirectTo, preview } = props.route.params;
	const [playbackErrorCode, setPlaybackErrorCode] = useState(0);
	const [config, setConfig] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showToast, setShowToast] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [toastLabel, setToastLabel] = useState("");
	const [toastMessage, setToastMessage] = useState("");
	const [packshot, setPackshot] = useState("");
	const [dubCard, setDubCard] = useState("");
	const [type, setType] = useState(ComponentTypeEnum.Primary);
	const parentProductIdRef = useRef(parentProductId);
	const navigation = useNavigation<any>();
	const controllerRef = useRef();
	const navigationAssignScreen = useNavigation<ScreenManagerNavigationProp>();
	const navigationVamAssignScreen = useNavigation<VamScreenManagerNavigationProp>();
	const onDismissSnackBar = () => setShowToast(false);
	const movieStoreValue = useMovieContentStore((state) => state.data);
	const streamPosition = useGenericContentStore((state: any) => state.streamPosition);
	const navigateEvent = useGenericContentStore((state: any) => state.navigateEvent);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const setCurrentResumeProgress = useGenericContentStore((state: any) => state.setCurrentResumeProgress);
	const setIsSuccess = useGenericContentStore((state: any) => state.setIsSuccess);
	const aggregatePageId = useGenericContentStore((state: any) => state.aggregatePageId);
	const dimensions = useGenericContentStore((state: any) => state.dimensions);
	const mqttDevices = useMqttStore((state: any) => state.mqttDevices);
	const mqttSender: MqttSenderService = useMqttStore((state: any) => state.mqttSender);
	const mqttReceiver: MqttReceiverService = useMqttStore((state: any) => state.mqttReceiver);
	const mqttPlayContent = useMqttStore((state: any) => state.mqttPlayContent);
	const setMqttPlayContent = useMqttStore((state: any) => state.setMqttPlayContent);
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const setVamDetails = useGenericContentStore((state: any) => state.setVamDetails);
	const [showCarToast, setShowCarToast] = useState(false);
	const [audioLangCode, setAudioLangCode] = useState("");
	const [subtitleLangCode, setSubtitleLangCode] = useState("");
	const [showStats, setShowStats] = useState(false);
	const [playerVariant, setPlayerVariant] = useState([]);
	const [playerStats, setPlayerStats] = useState([]);
	const [videoStat, setVideoStat] = useState();
	const [bufferedInfo, setBufferedInfo] = useState([]);
	const [manifestUrl, setManifestUrl] = useState("");
	const [files, setFiles] = useState<PlayerFiles>();
	const [alpha, setAlpha] = useState("");
	const [clickCount, setClickCount] = useState(1);
	const [showOnline, setShowOnline] = useState(false);
	const [hasSkipLess, setHasSkipLess] = useState(false);
	const profileName = localStorage.getItem(AsyncStorageKeys.profileName);
	const isToggleMQTT = useToggleStore((state: any) => state.isToggleMQTT);
	let defaultPlaySessionId = uuidv4();

	const init = async () => {
		defaultPlaySessionId = uuidv4();
		const regionOverride = localStorage.getItem(AsyncStorageKeys.RegionOverride);
		const languageOverride = localStorage.getItem(AsyncStorageKeys.LanguageOverride);
		const subtitlesLang: any = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.shakaSettingsSubtitle, false);
		const audioLang: any = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.shakaSettingsAudio, false);
		isStop = false;
		//@ts-ignore
		window.holdHeartbeat = true;

		if (!parentProductId || vamId || streamUrl) {
			return;
		}

		// check rights first
		const allOwned = await getOwnedStatus(parentProductIdRef.current, [TRANSACTION_TYPES.EST, TRANSACTION_TYPES.SVOD, TRANSACTION_TYPES.TVOD]);
		// @ts-ignore
		const [ownedStatus] = allOwned ? allOwned.filter((element: any) => element.owned).sort((a: any, b: any) => a - b) : null;
		const videoQuality = getVideoQualityByDeviceHeight(dimensions.screen.height);
		const availableLanguages: any = await getAvailableAudioLanguages(parentProductId, videoQuality, ownedStatus?.transactionType, ownedStatus?.countryId);
		// @ts-ignore
		window.transactionType = ownedStatus?.transactionType;
		if (regionOverride && languageOverride) {
			const langCode = `${languageOverride.toLowerCase()}-${regionOverride.toUpperCase()}`;
			const splitAudioCode = availableLanguages?.audioLanguages?.split(",");
			const splitSubtitleCode = availableLanguages?.subtitleLanguages?.split(",");

			if (splitAudioCode || audioLang) {
				const [audioCode] = splitAudioCode.filter((code: any) => code === langCode);
				setAudioLangCode(audioCode ?? audioLang);
			}
			if (splitSubtitleCode || subtitlesLang) {
				const [subtitleCode] = splitSubtitleCode.filter((code: any) => code === langCode);
				setSubtitleLangCode(subtitleCode ?? subtitlesLang);
			}
		}
	};

	const clearMqttPlayContent = () => {
		//@ts-ignore
		clearTimeout(window.connectionTimeoutTimer);
		//@ts-ignore
		window.connectionTimeoutTimer = null;
		//@ts-ignore
		clearInterval(window.syncMediaPositionTimer);
		//@ts-ignore
		window.syncMediaPositionTimer = null;
		//@ts-ignore
		window.ignoreCommands = false;
		//@ts-ignore
		window.mqttPlayContent = null;
		//@ts-ignore
		window.holdHeartbeat = false;
		//@ts-ignore
		window.ignoreCommands = false;
		localStorage.removeItem(AsyncStorageKeys.playSessionId);
		setMqttPlayContent(null);
	};

	/**
	 * Getting video player element
	 * @returns
	 */
	const playerElement = () => {
		//@ts-ignore
		const videoElement: {
			play: any;
			pause: any;
			paused: any;
			duration: number;
			currentTime: number;
			ended: boolean;
			end: any;
			playbackRate: number;
			ui: any;
			preservesPitch: any;
		} = controllerRef.current;
		if (videoElement) {
			return videoElement;
		}
	};

	const getPlayerState = (videoElement: any) => {
		if (!videoElement) return "playing";

		if (isStop) return "stopped";

		if (videoElement.paused) return "paused";

		return "playing";
	};

	/**
	 * Getting the primary deviceId
	 */
	const getDeviceId = () => {
		const deviceId = localStorage.getItem(AsyncStorageKeys.deviceId);
		return deviceId;
	};

	/**
	 * Function for resume receiver event listener
	 * @param message
	 */
	const onMediaPlayListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-play: ", message);
		const decodedBody = message?.body;
		const deviceId = getDeviceId();

		if (deviceId === decodedBody?.deviceId) {
			const videoElement = playerElement();
			videoElement?.play();
		}
	};

	/**
	 * Function for pause receiver event listener
	 * @param message
	 */
	const onMediaPauseListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-pause: ", message);
		const decodedBody = message?.body;
		const deviceId = getDeviceId();

		if (deviceId === decodedBody?.deviceId) {
			const videoElement = playerElement();
			videoElement?.pause();
		}
	};

	/**
	 * Function for restart receiver event listener
	 * @param message
	 */
	const onMediaRestartListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-restart: ", message);
		const decodedBody = message?.body;
		const deviceId = getDeviceId();

		if (deviceId === decodedBody?.deviceId) {
			const videoElement: any = playerElement();
			videoElement.currentTime = 0;
		}
	};

	/**
	 * Function for stop receiver event listener
	 * @param message
	 */
	const onMediaStopListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-stop: ", message);
		const decodedBody = message?.body;
		const deviceId = getDeviceId();

		if (deviceId === decodedBody?.deviceId) {
			isStop = true;
			onProgress();
			onGoBack();
		}
	};

	/**
	 * Function for seek receiver event listener
	 * @param message
	 */
	const onMediaSeekListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-seek: ", message);
		const playSessionId: any = localStorage.getItem(AsyncStorageKeys.playSessionId);
		const decodedBody = message?.body;
		const isProceed = decodedBody.playSessionId === playSessionId;

		if (
			//@ts-ignore
			!inWatchGroup(window.mqttPlayContent, getDeviceInfo()) ||
			//@ts-ignore
			!isMaster(window.mqttPlayContent, getDeviceInfo()) ||
			!isProceed ||
			!playSessionId
		)
			return;
		updateMasterPosition(decodedBody);
	};

	/**
	 * Function for updating the master player position
	 * @param decodedBody
	 * @returns
	 */
	const updateMasterPosition = (decodedBody: any) => {
		const videoElement = playerElement();

		if (!videoElement) return;
		videoElement.currentTime = decodedBody.position / 1000;
		videoElement.play();
	};

	const syncMediaPosition = (position: any) => {
		const videoElement = playerElement();
		// @ts-ignore
		window.videoElementTest = videoElement;

		if (!isToggleMQTT) return;

		//@ts-ignore
		if (!inWatchGroup(window.mqttPlayContent, getDeviceInfo()) || !videoElement || position > videoElement?.duration || window.isBuffering)
			return;

		//@ts-ignore
		let timeSinceLastKnownLeaderPosition = (Date.now() - window.timeSinceLastKnownLeaderPosition) / 1000;
		if (videoElement.paused) timeSinceLastKnownLeaderPosition = 0;
		//@ts-ignore
		let targetPosition = position + timeSinceLastKnownLeaderPosition + window.lastKnownLatency;
		//
		let posDiff = targetPosition - videoElement?.currentTime;

		// jump to specific timeframe if difference is too big
		if (posDiff >= 2 || posDiff <= -2) {
			AppLogger.log("[SYNC]: Large Jump");
			videoElement.playbackRate = 1;
			videoElement.currentTime = targetPosition;
			return;
		}
		AppLogger.log("[SYNC]: Fine Tuning");

		videoElement.preservesPitch = true;
		if (posDiff <= -1) {
			AppLogger.log("[SYNC]: I'm ahead (0.4)");
			videoElement.playbackRate = 1 - 0.4;
		} else if (posDiff > -1 && posDiff <= -0.5) {
			AppLogger.log("[SYNC]: I'm ahead (0.2)");
			videoElement.playbackRate = 1 - 0.2;
		} else if (posDiff > -0.5 && posDiff <= -0.12) {
			AppLogger.log("[SYNC]: I'm ahead (0.1)");
			videoElement.playbackRate = 1 - 0.1;
		} else if (posDiff >= 1) {
			AppLogger.log("[SYNC]: I'm behind (0.4)");
			videoElement.playbackRate = 1 + 0.4;
		} else if (posDiff >= 0.5 && posDiff < 1) {
			AppLogger.log("[SYNC]: I'm behind (0.2)");
			videoElement.playbackRate = 1 + 0.2;
		} else if (posDiff >= 0.15 && posDiff < 0.5) {
			AppLogger.log("[SYNC]: I'm behind (0.1)");
			videoElement.playbackRate = 1 + 0.1;
		} else {
			videoElement.playbackRate = 1;
		}

		//@ts-ignore
		AppLogger.log("[SYNC]: Latency: " + window.lastKnownLatency);
		AppLogger.log("[SYNC]: Duration: " + videoElement?.duration);
		AppLogger.log("[SYNC]: CurrentTime: " + videoElement?.currentTime);
		AppLogger.log("[SYNC]: Diff: " + posDiff);
		AppLogger.log("[SYNC]: targetPosition: " + targetPosition);
		AppLogger.log("[SYNC]: timeSinceLastKnownLeaderPosition: " + timeSinceLastKnownLeaderPosition);
		AppLogger.log("[SYNC]: Current playback rate: " + videoElement.playbackRate);
	};

	/**
	 * Function to receive command from Master device
	 * @param message
	 */
	const onMediaSyncListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-sync: ", message);
		const deviceId = getDeviceId();
		const decodedBody = message?.body;
		const playSessionId: any = localStorage.getItem(AsyncStorageKeys.playSessionId) ?? defaultPlaySessionId;
		if (!playSessionId || playSessionId !== decodedBody.playSessionId || deviceId === message.deviceId) return;

		//@ts-ignore
		window.lastKnownLeaderPosition = (decodedBody.position - 500) / 1000;
		//@ts-ignore
		window.timeSinceLastKnownLeaderPosition = Date.now();
		const videoElement = playerElement();
		//@ts-ignore
		const currentDevice = getDeviceInfo();

		//@ts-ignore
		AppLogger.log("lastKnownLeaderPosition", window.lastKnownLeaderPosition);

		const controllerCommand = () => {
			if (!videoElement) return;

			if (
				decodedBody.state !== "stopped" &&
				message.deviceId !== deviceId &&
				inWatchGroup(mqttPlayContent, currentDevice) &&
				isMaster(mqttPlayContent, currentDevice)
			) {
				//@ts-ignore
				window.ignoreCommands = true;
				videoElement.currentTime = decodedBody.position / 1000;
				//@ts-ignore
				setTimeout(() => (window.ignoreCommands = false), 1000);
			}

			if (decodedBody.state === "stopped") {
				//@ts-ignore
				window.ignoreCommands = true;
				//@ts-ignore
				setTimeout(() => (window.ignoreCommands = false), 1000);
				return;
			}

			if (decodedBody.state === "paused") {
				//@ts-ignore
				window.ignoreCommands = true;
				videoElement?.pause();
				//@ts-ignore
				setTimeout(() => (window.ignoreCommands = false), 1000);
				return;
			}

			if (decodedBody.state !== "paused" && decodedBody.position === 0) {
				videoElement.currentTime = 0;
				return;
			}

			videoElement?.play();
		};

		if (playSessionId === decodedBody.playSessionId) {
			controllerCommand();
		}
	};

	const onMediaSubtitleListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-subtitle: ", message);
		const playSessionId: any = localStorage.getItem(AsyncStorageKeys.playSessionId);
		const decodedBody = message.body;
		if (!playSessionId) return;

		let groupDevices: any = localStorage.getItem(AsyncStorageKeys.watchGroup);
		groupDevices = JSON.parse(groupDevices);
		let currentPlaySessionId = "";

		groupDevices?.forEach((groups: any) => {
			if (groups.devices.length === 0) return;
			groups.devices?.forEach((device: any) => {
				if (device.deviceId === message.deviceId) currentPlaySessionId = groups.playSessionId;
			});
		});

		if (playSessionId === currentPlaySessionId) {
			PlayerEventHandlerExternal.emit("onSubTitleChanged", decodedBody.subtitle);
		}
	};

	const onMediaAudioListener = (message: any) => {
		AppLogger.log("[MQTT] Received media-audio: ", message);
		const playSessionId: any = localStorage.getItem(AsyncStorageKeys.playSessionId);
		const decodedBody = message.body;
		if (!playSessionId) return;

		let groupDevices: any = localStorage.getItem(AsyncStorageKeys.watchGroup);
		groupDevices = JSON.parse(groupDevices);
		let currentPlaySessionId = "";

		groupDevices?.forEach((groups: any) => {
			if (groups.devices.length === 0) return;
			groups.devices?.forEach((device: any) => {
				if (device.deviceId === message.deviceId) currentPlaySessionId = groups.playSessionId;
			});
		});

		if (playSessionId === currentPlaySessionId) {
			PlayerEventHandlerExternal.emit("onAudioChanged", decodedBody.audio);
		}
	};

	const getPlaySession = () => {
		const playSessionId: any = localStorage.getItem(AsyncStorageKeys.playSessionId);
		return playSessionId;
	};

	/**
	 * generate MqttPlayContent based on videoElement details
	 * @param videoElement
	 * @param contentId
	 * @returns
	 */
	const generateMqttPlayContent = (videoElement: any, contentId: any, vamId: any) => {
		return {
			contentId: parseInt(contentId),
			created: new Date().getTime(),
			deviceId: "",
			duration: Math.round(videoElement?.duration * 1000),
			playSessionId: getPlaySession() ?? defaultPlaySessionId,
			position: parseFloat(parseFloat((videoElement?.currentTime * 1000).toString()).toFixed(2)) + 500,
			screenId: "",
			state: getPlayerState(videoElement),
			vamId,
		};
	};

	/**
	 * [MQTT] Send media-position
	 * @param videoElement
	 * @param contentId
	 * @param vamId
	 * @param isSend - (optional) If set to true, it will send mqtt media-position. Otherwise it will only update the mqttPlayContent
	 * @returns
	 */
	const broadcastPosition = (videoElement: any, contentId: any, vamId: any, isSend?: boolean) => {
		//@ts-ignore
		if (!mqttSender || streamUrl || window.ignoreCommands || !isToggleMQTT) return;

		const mqttPlayContent = generateMqttPlayContent(videoElement, contentId, vamId);
		//@ts-ignore
		window.mqttPlayContent = mqttPlayContent;

		if (isSend) {
			if (inWatchGroup(mqttPlayContent, getDeviceInfo())) {
				//@ts-ignore
				window.ignoreCommands = true;
				mqttSender.mediaSyncCommand(mqttPlayContent);
				//@ts-ignore
				setTimeout(() => (window.ignoreCommands = false), 1000);
				return;
			}
			mqttSender.broadcastPositionCommand(mqttPlayContent);
		}
	};

	const onProgress = () => {
		const videoElement = playerElement();

		if (streamUrl || dubCard) return;

		if (videoElement) {
			const mqttPlayContent = generateMqttPlayContent(videoElement, parentProductId, vamId ?? 0);
			//@ts-ignore
			window.mqttPlayContent = mqttPlayContent;
			AsyncStorage.setItem(AsyncStorageKeys.idleExpiry, getDateTimePlusHour().toString());

			//@ts-ignore
			window.holdHeartbeat = false;
			if (
				inWatchGroup(mqttPlayContent, getDeviceInfo()) &&
				!isMaster(mqttPlayContent, getDeviceInfo()) &&
				//@ts-ignore
				window.lastKnownLeaderPosition &&
				//@ts-ignore
				window.mqttPlayContent &&
				//@ts-ignore
				!window.ignoreCommands &&
				//@ts-ignore
				!window.syncMediaPositionTimer
			) {
				//@ts-ignore
				window.syncMediaPositionTimer = setInterval(() => {
					AppLogger.log("[SYNC] syncMediaPositionTimer: Running");
					//@ts-ignore
					syncMediaPosition(window.lastKnownLeaderPosition);
				}, 50);
			}

			if (navigator && videoElement.playbackRate) {
				//@ts-ignore
				navigator.mediaSession.setPositionState({
					duration: videoElement.duration,
					playbackRate: videoElement.playbackRate,
					position: videoElement.currentTime,
				});
			}
		}
	};

	const onGetStats = (stats: any) => {
		setPlayerVariant(stats.getStatVariant.find((variant: any) => variant.active === true));
		setPlayerStats(stats.getStat);
		setBufferedInfo(stats.getStatBufferedInfo);
		setVideoStat(stats.getVideoStat);
	};

	const onGetFiles = (files: any) => {
		setFiles(files);
	};

	const showNerds = () => {
		const newCount = clickCount + 1;
		setClickCount(newCount);
		if (clickCount === 10) {
			setShowStats(true);
			return setClickCount(1);
		}
	};

	/**
	 * Setup for Pre  Roll
	 */
	const setUpPreRoll = (videoRequest: any) => {
		if (!cancelPreRoll) {
			let preRoll = videoRequest?.productTracks?.filter(
				(productTrack: { fileType: number }) => productTrack.fileType === PRODUCT_TRACK_FILE_TYPES.PRE_ROLL
			);

			let forcedPreRoll = videoRequest?.productTracks?.filter(
				(productTrack: { fileType: number }) => productTrack.fileType === PRODUCT_TRACK_FILE_TYPES.SKIP_LESS_PRE_ROLL
			);

			let hasForced = forcedPreRoll.length !== 0;
			setHasSkipLess(hasForced);

			if ((preRoll.length !== 0 || forcedPreRoll.length !== 0) && resumeProgress === 0 && preRollCollection.length === 0) {
				//@ts-ignore
				window.preRollPlaying = true;
				preRollCollection = [...preRoll, ...forcedPreRoll];
			}

			const rollCollection = preRollCollection.shift();
			skipLess = rollCollection?.fileType === PRODUCT_TRACK_FILE_TYPES.SKIP_LESS_PRE_ROLL;
			preRollUrl = rollCollection?.url;

			if (preRollCollection.length === 0) {
				cancelPreRoll = true;
			}
			return;
		}
		//@ts-ignore
		window.preRollPlaying = false;
		cancelPreRoll = true;
		preRollUrl = "";
		preRollCollection = [];
		skipLess = false;
	};

	const checkAvailableAudio = async (audioLanguages: any) => {
		const [audio] = audioLanguages?.slice(0, 1);
		const audioStore = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.shakaSettingsAudio, false);
		const foundAudio = audioLanguages.find((item: any) => item.toLowerCase() === audioStore);

		if (foundAudio) return;

		CacheStorageUtils.setCacheItem(AsyncStorageKeys.shakaSettingsAudio, audio ?? "en-US", false);
		setAudioLangCode(audio ?? "en-US");
	};

	/**
	 * Setup movie if not trailer, vam, dubcards
	 */
	const setUpMovie = async (metadata: any) => {
		//@ts-ignore
		await endProgress(window.transactionType ?? 1);
		dubCards = [];

		// check rights first
		const allOwned: any = await getOwnedStatus(parentProductIdRef.current, [
			TRANSACTION_TYPES.EST,
			TRANSACTION_TYPES.SVOD,
			TRANSACTION_TYPES.TVOD,
		]);
		const found = allOwned ? allOwned.find((element: { owned: any }) => element.owned) : null;
		const [ownedStatus] = allOwned.filter((element: any) => element.owned).sort((a: any, b: any) => a - b);
		const unavailable = isNaN(parentProductId);

		if (unavailable) {
			setToastMessage(globalAny.language.movie_not_available);
			setType(ComponentTypeEnum.Secondary);
			setShowToast(true);
			redirect();
			return;
		}

		if (!found) {
			setToastMessage(globalAny.language.not_yet_redeem);
			setType(ComponentTypeEnum.Secondary);
			setShowToast(true);
			redirect();
			return;
		}

		let transactionType = found.transactionType;

		try {
			const videoQuality = getVideoQualityByDeviceHeight(dimensions.screen.height);
			const reqGetVideo = getVideo(parentProductIdRef.current, transactionType, videoQuality);
			const reqGetAudioLanguages = getAvailableAudioLanguages(parentProductIdRef.current, videoQuality, ownedStatus?.transactionType, ownedStatus?.countryId);
			const parallelRequests = await Promise.allSettled([reqGetVideo, reqGetAudioLanguages]);
			const subtitle: any = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.shakaSettingsSubtitle, false);
			// @ts-ignore
			const [videoRequest, audioLanguagesStr] = parallelRequests.map((i) => i.value);

			if (videoRequest.responseCode === 40079) {
				setToastMessage(globalAny.language.concurrent_limit);
				setType(ComponentTypeEnum.Secondary);
				setToastLabel(globalAny.language.ok);
				setShowToast(true);
				redirect();
				return;
			}

			localStorage.setItem(AsyncStorageKeys.trackingId, videoRequest?.trackingId);

			setAlpha(videoRequest.alpha.toUpperCase());
			let trackUrl = "";
			let subtitleLanguages: any = [];
			let thumbnailUrl = "";
			if (videoRequest.productTracks) {
				let dashTracks = videoRequest.productTracks.filter(
					(productTrack: { fileType: number }) => productTrack.fileType === PRODUCT_TRACK_FILE_TYPES.DASH
				);
				if (dashTracks.length > 0) {
					trackUrl = dashTracks[0].url; // Bring back when we have working clips
				}
				let subtitleTracks = videoRequest.productTracks.filter(
					(productTrack: { fileType: number }) =>
						productTrack.fileType === PRODUCT_TRACK_FILE_TYPES.SUBTITLE_VTT ||
						productTrack.fileType === PRODUCT_TRACK_FILE_TYPES.FORCED_VTT
				);

				let thumbnailUrls = videoRequest.productTracks.filter(
					(productTrack: { fileType: number }) => productTrack.fileType === PRODUCT_TRACK_FILE_TYPES.THUMBNAIL
				);

				if (thumbnailUrls.length > 0) {
					thumbnailUrl = thumbnailUrls[0].url; // Bring back when we have working clips
				}

				setUpPreRoll(videoRequest);

				subtitleLanguages = subtitleTracks.map((value: { language: any; url: any; fileType: number }) => ({
					title: value.fileType === PRODUCT_TRACK_FILE_TYPES.FORCED_VTT ? "forced" : value.language, // title shown in the menu
					language: value.language, // language used to select the track
					vtt: value.url, // URL to the VTT file
					track: value.fileType === PRODUCT_TRACK_FILE_TYPES.FORCED_VTT ? "forced" : value.language,
					isForced: value.fileType === PRODUCT_TRACK_FILE_TYPES.FORCED_VTT,
				}));
			}

			const subtitleExist = subtitleLanguages.some((item: any) => item.language === subtitle);

			if (!subtitleExist) {
				CacheStorageUtils.setCacheItem(AsyncStorageKeys.shakaSettingsSubtitle, "", false);
				CacheStorageUtils.setCacheItem(AsyncStorageKeys.subtitleOnAndOff, false, false);
			}

			let audioLanguages: { title: string; track: string }[] = [];
			if (audioLanguagesStr && audioLanguagesStr?.audioLanguages) {
				let audioLanguagesArr = audioLanguagesStr.audioLanguages.split(",");

				audioLanguages = await audioLanguagesArr.map((language: string) => ({
					title: getLanguageName(language), // title shown in the menu
					track: language, // Language use for the audio track. MUST BE UNIQUE as this is used to select the track
				}));
				checkAvailableAudio(audioLanguagesArr);
			}

			const dubCardsVideo: any = await getDubCards(parentProductId);
			dubCards = dubCardsVideo.dubCards;
			AppLogger.log("dubCards", dubCards);

			// Broadcast to other devices that this device is playing
			AppLogger.log("Broadcasting available media playback options");

			if (preRollUrl && resumeProgress === 0) {
				setTimeout(setUpConfig(preRollUrl, "", "", "", [], [], [], metadata), 10);
				return;
			}

			cancelPreRoll = true;
			preRollUrl = "";
			skipLess = false;
			//@ts-ignore
			window.preRollPlaying = false;

			setUpConfig(
				trackUrl,
				videoRequest.playReadyLicenseServer,
				videoRequest.widevineLicenseServer,
				videoRequest.batchKeyWidevineUrl,
				subtitleLanguages,
				audioLanguages,
				thumbnailUrl,
				metadata
			);
		} catch (reason) {
			setPlaybackErrorCode(1);
			AppLogger.log(`[PLAYBACK] setUpMovie Error: ${reason}`);
		}
	};

	/**
	 * Load the video
	 */
	const loadVideo = async () => {
		const metadata: any = await getMetadata(parentProductIdRef.current);
		if (dubCard && !streamUrl && !vamId) {
			setTimeout(setUpConfig(dubCard, "", "", "", [], [], "", metadata), 10);
			return;
		}

		if (streamUrl) {
			setTimeout(setUpConfig(streamUrl, "", "", "", [], [], "", metadata), 10);
			return;
		}

		if (vamId && vamId !== "undefined") {
			const vam = await getVamData(metadata);
			await getVamPlayerDetails(vam?.id).then((res: any) => {
				if (res.playerDetails) {
					const playerDetails = res.playerDetails;
					const subTitleTracks = playerDetails?.responseTracks.filter((tracks: any) => tracks.fileType === PRODUCT_TRACK_FILE_TYPES.SUBTITLE_VTT );
					const vamSubtitle = subTitleTracks.map((value: { language: any; url: any; fileType: number }) => ({
						title: value.language,
						language: value.language,
						vtt: value.url,
						track: value.language,
						isForced: false,
					}));
					setUpConfig(
						playerDetails.manifestUrl,
						playerDetails.playReadyLicenseServer,
						playerDetails.widevineLicenseServer,
						playerDetails.batchKeyWidevineUrl,
						vamSubtitle,
						[],
						"",
						metadata
					);
				}
			});
			return;
		}

		await setUpMovie(metadata);
	};

	const progressVideoEvent = async (isEnded: boolean = false) => {
		if (!cancelPreRoll) return;

		const videoElement = playerElement();
		if (videoElement && parentProductId && !vamId && !streamUrl) {
			const currentTime = Math.round(videoElement.currentTime * 1000);
			const trackingId = localStorage.getItem(AsyncStorageKeys.trackingId);

			if (!currentTime || !trackingId || trackingId === "undefined") return;

			isEnded && videoElement.pause();

			setProgressVideo(
				parseInt(parentProductId),
				// @ts-ignore
				window.isDubCardPlaying ? 0 : Math.round(currentTime) ?? 0,
				1,
				Math.round(videoElement.duration * 1000) ?? 0,
				isEnded ? ProgressEvents.end : ProgressEvents.update,
				trackingId,
				// @ts-ignore
				window.transactionType ?? 1
			);
			!videoElement.paused && localStorage.setItem(AsyncStorageKeys.streamProgress, currentTime.toString());
		}

		if (videoElement && vamId) {
			isEnded && videoElement.pause();
		}
	};

	const onErrorEvent = (event: any) => {
		// Extract the shaka.util.Error object from the event.
		onError(event.detail ? event.detail : event);
	};

	const onStartEvent = (event: any) => {
		const videoElement = playerElement();
		if (videoElement && parentProductId && !vamId && !streamUrl) {
			const trackingId = localStorage.getItem(AsyncStorageKeys.trackingId) ?? "";

			setProgressVideo(
				parseInt(parentProductId),
				Math.round(resumeProgress) ?? 0,
				1,
				Math.round(videoElement.duration * 1000) ?? 0,
				ProgressEvents.start,
				trackingId,
				// @ts-ignore
				window.transactionType ?? 1
			);
			localStorage.setItem(AsyncStorageKeys.parentProductId, parentProductId);
			localStorage.setItem(AsyncStorageKeys.totalDuration, Math.round(videoElement.duration * 1000).toString());
		}
	};

	const onEndedEvent = async (isEnded?: any) => {
		if (preRollCollection.length >= 0 && preRollUrl && resumeProgress === 0 && !vamId && !streamUrl) {
			reloadVideo();
			return;
		}

		if (dubCards.length !== 0 && !vamId && !streamUrl) {
			const dubCard: any = dubCards.shift();
			AppLogger.log("dubCard", dubCard);
			AppLogger.log("dubCards", dubCards);
			if (dubCard?.fileName && isEnded?.type === "ended" && !streamUrl && !vamId) {
				//@ts-ignore
				window.isDubCardPlaying = true;
				setDubCard(dubCard?.fileName);
				return;
			}
		} else {
			//@ts-ignore
			window.isDubCardPlaying = false;
			isStop = true;
			onProgress();
			onGoBack();
		}
	};

	const onError = (error: any) => {
		//@ts-ignore
		if (skipLess || window.preRollPlaying) {
			cancelPreRoll = true;
			reloadVideo();
			return;
		}

		AppLogger.error("[PLAYBACK]" + globalAny.language.playback_error, error.toString());
		setPlaybackErrorCode(error.code);
	};

	const getVamData = async (metadata: any) => {
		const vams: any = await getVamDetails(vamId);

		if (vams?.vam) {
			const vam = vams?.vam;
			return {
				id: vam?.id,
				title: metadata?.title ? `${metadata?.title} - ${vam?.title}` : vam?.title,
				image: vam?.imageUrl,
			};
		}

		return {
			id: 0,
			title: "",
			image: "",
		};
	};

	const getAudioConfig = () => {
		const shakaAudioLanguage = JSON.parse(localStorage.getItem(AsyncStorageKeys.shakaSettingsAudio) ?? '{}');
		const language = shakaAudioLanguage?.data ?? audioLangCode;
		const shakaAudioChannel = JSON.parse(localStorage.getItem(AsyncStorageKeys.shakaSettingsAudioChannel) ?? '{}');
		const channelCount = shakaAudioChannel?.data ?? '6';

		return {
			language,
			channelCount
		}
	}

	/**
	 * Helper function to create the Config that the sony-shaka player needs for playback and drm
	 * @param url
	 * @param metadata
	 * @param playReadyLicenseServer
	 * @param widevineLicenseServer
	 * @param audioLanguages
	 * @param subtitleLanguages
	 * @param onErrorEvent
	 * @param onEndedEvent
	 */
	const createConfig = async ({
		url,
		metadata,
		playReadyLicenseServer,
		widevineLicenseServer,
		batchKeyWidevineUrl,
		audioLanguages,
		subtitleLanguages,
		onErrorEvent,
		onEndedEvent,
		thumbnailUrl,
	}: any) => {
		let currentVamDetails;
		let title, rating;
		const subtitlesEnabled: any = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.subtitleOnAndOff, false);

		if (!streamUrl && vamId) {
			currentVamDetails = await getVamData(metadata);
			title = currentVamDetails.title;
			rating = null;
		} else {
			title = metadata.title;
			rating = metadata.ratingReason;
		}

		if (navigator) {
			let portraitImage;
			if (metadata) {
				portraitImage = metadata.images?.filter((val: any) => val.width === 600 && val.height === 900)?.map((val: any) => val.url)[0];
			}
			const mediaMetadata = portraitImage
				? {
					title: title ?? "TEST",
					artwork: [
						{
							src: portraitImage,
							sizes: "600x900",
							type: "image/webp",
						},
					],
				} : {
					title: title ?? "TEST"
				};
			//@ts-ignore
			navigator.mediaSession.metadata = new MediaMetadata(mediaMetadata);
			document.title = title;
		}

		const videoQuality = getVideoQualityByDeviceHeight(dimensions.screen.height);
		//@ts-ignore
		// const groupId = getGroupId(localStorage.getItem(AsyncStorageKeys.playSessionId), getDeviceId());
		// groupSyncColor = groupId ? groupColor.find((color) => color.group === groupId)?.color : shakaPlayColor;
		//@ts-ignore
		const isDubCardPlay = window.isDubCardPlaying;
		const audioConfig = getAudioConfig();
		const _config = {
			videoId: "bcore-shaka", // ID of the video element on the page
			manifestUri: url, // URL to the video manifest
			//previewUri: 'https://cdn.watchcorridor.com/samples/ZLDT_SP/thumb-list.vtt', // VTT used to display the seek preview
			title: title, // Title of the video
			rating: rating, // Info shown under the title on the overlay
			selectedAudioLanguage: vamId ? 'en-us' : audioConfig.language, // The currently selected audio language
			defaultAudioLanguage: vamId ? 'en-us' : localStorage.getItem(AsyncStorageKeys.LanguageOverride)?.toLowerCase(),
			selectedAudioChannel: audioConfig.channelCount, // The currently selected audio channel
			defaultAudioChannel: '6',
			selectedSubtitleLanguage: subtitleLangCode, // The currently selected subtitle language
			defaultSubtitleLanguage: subtitleLangCode,
			subtitlesEnabled: subtitlesEnabled ?? false, // Whether subtitles are enabled
			// subtitleClassList - The list of classes used to style the subtitles. This example shows the
			// default value.
			subtitleClassList:
				"edge-style-drop-shadow text-opacity-100 font-family-roboto border-color-none background-color-none border-opacity-100 background-opacity-100 text-size-20 text-color-white",
			audioLanguages: audioLanguages,
			subtitleLanguages: subtitleLanguages,
			customHeaders: [],
			widevineLicenseServer: batchKeyWidevineUrl,
			onError: onErrorEvent,
			onEnded: onEndedEvent,
			onStart: onStartEvent,
			playRangeStart: preRollUrl || isDubCardPlay ? 0 : resumeProgress / 1000 || streamPosition || 0,
			isTrailer: !!streamUrl,
			isPreRoll: !!preRollUrl,
			isPostRoll: !!preRollUrl,
			isSkipLess: skipLess,
			isVam: redirectTo === direction.studio,
			watchNowTitle: metadata?.title,
			thumbnailUrl: thumbnailUrl,
			groupSyncColor: groupSyncColor,
			isInMotion: carActivity === CarActivity.INMOTION,
			videoQuality,
			isPassenger: profileName === ProfileName.PassengerScreen,
			//@ts-ignore
			inWatchGroup: inWatchGroup(window.mqttPlayContent, getDeviceInfo()),
			isToggleMQTT: isToggleMQTT,
			getLanguageName: getLanguageName,
		};

		// @ts-ignore
		setConfig(_config);
	};

	/**
	 * Setup and configure the player
	 * @param url
	 * @param playReadyLicenseServer
	 * @param widevineLicenseServer
	 * @param subtitleLanguages
	 * @param audioLanguages
	 */
	const setUpConfig = async (
		url: string,
		playReadyLicenseServer: string | null,
		widevineLicenseServer: string,
		batchKeyWidevineUrl: string,
		subtitleLanguages: any,
		audioLanguages: any,
		thumbnailUrl?: any,
		metadata?: any
	) => {
		if (url == "") {
			navigation.goBack();
		} else {
			setManifestUrl(url);

			createConfig({
				url,
				metadata,
				playReadyLicenseServer,
				widevineLicenseServer,
				batchKeyWidevineUrl,
				audioLanguages,
				subtitleLanguages,
				onErrorEvent,
				onEndedEvent,
				thumbnailUrl,
			});
		}
	};

	/**
	 * broadcast media-position, this will triggered every pause/play button is clicked
	 */
	const broadcastPlayPause = () => {
		const videoElement = playerElement();
		//@ts-ignore
		if (!videoElement || streamUrl || window.holdHeartbeat || !mqttSender) return;
		//@ts-ignore
		window.holdHeartbeat = true;
		const mqttPlayContent = generateMqttPlayContent(videoElement, parentProductId, vamId ?? 0);
		mqttSender.mediaSyncCommand(mqttPlayContent);
		//@ts-ignore
		setTimeout(() => (window.holdHeartbeat = false), 1000);
	};

	/**
	 * goBack use-cases for React Native:
	 * 1. User is in other pages like homescreen, settings, etc.: Use navigation.goBack to navigate where the user went when the screen is navigated to movie screen using mqtt
	 * 2. User refreshes the movie screen page (either watching main movie or vam): We declare the navigation explicitly like using navigation for metadata screen or title details when either they watched vam or movie. We do this because React Native doesn't have any memory where it should go back when we refreshed the page and it started on movie player first. Without it declaring explicity, it would cause issues or routing errors
	 */
	const onGoBack = () => {
		document.documentElement.setAttribute("lang", localStorage.getItem(AsyncStorageKeys.LanguageOverride) ?? "EN");
		isStop = true;
		//@ts-ignore
		window.holdHeartbeat = true;
		progressVideoEvent(true);
		clearMqttPlayContent();
		setIsSuccess(false, false);
		//@ts-ignore
		window.preRollPlaying = false;
		//@ts-ignore
		window.isDubCardPlaying = false;
		cancelPreRoll = false;
		preRollUrl = "";
		preRollCollection = [];

		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}

		if (navigateEvent === Pages.vamAggregatePage) {
			navigation.navigate(routeVamAggregatePage, { customListId: aggregatePageId.id });
			setNavigateEvent("");
			return;
		}

		if (vamId && location === direction.main && redirectTo === direction.studio) {
			navigation.navigate(routeNewCuration);
			return;
		}
		if (vamId && navigateEvent === direction.main && redirectTo === direction.tdp) {
			setSelectedTabs(2);
			setNavigateEvent("");
			navigation.navigate(routeTitleDetails, {
				parentProductId,
				preview,
			});
			return;
		}

		if (location === direction.assign && redirectTo === direction.tdp) {
			navigationVamScreenManager.navigate(routeVamScreenManager, {
				location: Pages.assignScreen,
				parentProductId,
				vamId,
				redirectTo: direction.tdp,
			});
			return;
		}

		if (location === direction.assign && redirectTo === direction.studio) {
			navigationVamScreenManager.navigate(routeVamScreenManager, {
				location: Pages.assignScreen,
				parentProductId,
				vamId,
				redirectTo: direction.studio,
			});
			return;
		}

		setNavigateEvent("");
		setSelectedTabs(0);
		navigation.navigate(routeTitleDetails, {
			parentProductId,
			preview,
		});
	};

	/** Return to Home Screen */
	const onGoHome = () => {
		isStop = true;
		onProgress();
		progressVideoEvent(true);
		clearMqttPlayContent();
		navigation.navigate(preview ? routeLogin : routeHome);
	};

	/**
	 * Restart the player.
	 */
	const onRestart = () => {
		//@ts-ignore
		const videoElement: any = playerElement();
		videoElement.currentTime = 0;

		if (videoElement.paused) {
			videoElement.play();
		}
	};

	/** Navigate to assign screen page */
	const onScreenManager = () => {
		if (mqttDevices.length === 1) {
			setToastMessage(globalAny.language.assign_single_screen_message);
			setType(ComponentTypeEnum.Processing);
			setShowToast(true);
			return;
		}
		//@ts-ignore
		window.onScreenManager = true;
		if (vamId) {
			navigationVamAssignScreen.push(routeVamScreenManager, {
				location: Pages.assignScreen,
				parentProductId,
				vamId,
				redirectTo: `${location}-${direction.player}`,
			});
			return;
		}
		setVamDetails("");
		setCurrentResumeProgress(resumeProgress);
		navigationAssignScreen.push(routeScreenManager, { location: Pages.assignScreen, parentProductId, redirectTo: direction.player });
	};

	/**Navigate to player */
	const onWatchNow = () => {
		if (hasSkipLess) {
			preRollCollection = preRollCollection?.filter(
				(productTrack: { fileType: number }) => productTrack.fileType === PRODUCT_TRACK_FILE_TYPES.SKIP_LESS_PRE_ROLL
			);
			reloadVideo();
			return;
		}

		if (preRollCollection.length >= 0 && resumeProgress === 0 && !vamId && !streamUrl) {
			cancelPreRoll = true;
			reloadVideo();
			return;
		}

		navigation.reset({
			index: 0,
			routes: [
				{
					// @ts-ignore
					name: routeTitleDetails,
					params: { parentProductId },
				},
			],
		});
		setSelectedTabs(0);
	};

	/**
	 * Function for sending media seek command upon moving on seek bar
	 */
	const onSendMediaSeek = () => {
		const videoElement = playerElement();

		if (videoElement && !streamUrl) {
			const mqttPlayContent = generateMqttPlayContent(videoElement, parentProductId, vamId ?? 0);
			if (inWatchGroup(mqttPlayContent, getDeviceInfo()) && mqttPlayContent) {
				broadcastPosition(videoElement, parentProductId, vamId, true);
			}
		}
	};

	const onSeekButtonListener = () => {
		const videoElement = playerElement();

		if (videoElement) {
			onSendMediaSeek();
			progressVideoEvent(false);
		}
	};

	const onAudioChangeListener = (audio: any) => {};

	const onSubtitleChangeListener = (subtitle: any, status: any, isCode: boolean) => {};

	const onBufferListener = async (e: any) => {
		//@ts-ignore
		window.isBuffering = true;
		//@ts-ignore
		window.ignoreCommands = true;
		// @ts-ignore
		if (!window.connectionTimeoutTimer) {
			// @ts-ignore
			window.connectionTimeoutTimer = setTimeout(() => {
				//@ts-ignore
				clearTimeout(window.connectionTimeoutTimer);
				//@ts-ignore
				window.connectionTimeoutTimer = null;
				setPlaybackErrorCode(1003);
			}, 60000);
		}

		//@ts-ignore
		if (!e.buffering) {
			//@ts-ignore
			window.isBuffering = false;
			//@ts-ignore
			window.ignoreCommands = false;
			//@ts-ignore
			clearTimeout(window.connectionTimeoutTimer);
			//@ts-ignore
			window.connectionTimeoutTimer = null;
		}
	};

	const onPingListener = (message: any) => {
		AppLogger.log("[MQTT] Ping Received:", message);
		//@ts-ignore
		if (inWatchGroup(window.mqttPlayContent, getDeviceInfo()) && isMaster(window.mqttPlayContent, getDeviceInfo())) {
			const decodedBody = message.body;
			mqttSender.sendPingBackCommand(decodedBody.id);
		}
	};

	const onPingBackListener = (message: any) => {
		AppLogger.log("[MQTT] Ping Back Received:", message);
		//@ts-ignore
		window.pingBackReceived = Date.now();
		//@ts-ignore
		if (inWatchGroup(window.mqttPlayContent, getDeviceInfo()) && !isMaster(window.mqttPlayContent, getDeviceInfo())) {
			//@ts-ignore
			window.lastKnownLatency = parseFloat(((window.pingBackReceived - window.pingSent) / 1000 / 2).toFixed(2));
			//@ts-ignore
			AppLogger.log("lastKnownLatency", window.lastKnownLatency);
		}
	};

	const reloadVideo = () => {
		setConfig(null);

		setTimeout(() => {
			loadVideo();
		}, 1000);
	};

	const reconnectNetwork = () => {
		reloadVideo();
		setTimeout(() => {
			setToastMessage(globalAny.language.reconnect_failed);
			setType(ComponentTypeEnum.Secondary);
			setToastLabel(globalAny.language.ok);
			setShowToast(true);
			setTimeout(() => onGoBack(), 5000);
		}, 5000);
	};

	const checkErrorNetwork = () => {
		setToastMessage(globalAny.language.network_reconnecting);
		setType(ComponentTypeEnum.Secondary);
		setShowToast(true);
		reconnectNetwork();
	};

	const redirect = () => {
		setTimeout(() => {
			onGoBack();
		}, 4500);
	};

	const handleBackButtonClick = (event: any) => {
		event.preventDefault();
		//@ts-ignore
		progressVideoEvent(!window.onScreenManager);
	};

	const unload = async (e: any) => {
		clearInterval(progressInterval);
		//@ts-ignore
		clearInterval(window.syncMediaPositionTimer);
		//@ts-ignore
		window.syncMediaPositionTimer = null;
		progressVideoEvent(true);
		clearMqttPlayContent();
	};

	/**Function to reset default settings */
	const restoreDefaultSettings = () => {
		PlayerEventHandlerExternal.emit("onRestoreDefaultSettings", true);
		setShowModal(false);
		setShowToast(true);
		setType(ComponentTypeEnum.Primary);
		setToastMessage(globalAny.language.default_settings_restored);
	};

	useEffect(() => {
		init();

		if (!streamUrl && !dubCard)
			mqttSender?.broadcastPositionCommand({
				...generateMqttPlayContent("", parentProductId, vamId),
			});

		window.addEventListener("beforeunload", unload);
		window.addEventListener("popstate", handleBackButtonClick);

		window.addEventListener("online", () => {
			//@ts-ignore
			clearTimeout(window.connectionTimeoutTimer);
			//@ts-ignore
			window.connectionTimeoutTimer = null;
			setPlaybackErrorCode(0);
			setShowToast(false);
			setShowOnline(true);
		});

		window.addEventListener("offline", () => {
			setPlaybackErrorCode(1002);
		});
		

		if (!mqttReceiver || !isToggleMQTT) return;
		const mediaPlayListener = mqttReceiver.addOnMediaPlayListener(onMediaPlayListener);
		const mediaPauseListener = mqttReceiver.addOnMediaPauseListener(onMediaPauseListener);
		const mediaRestartListener = mqttReceiver.addOnMediaRestartListener(onMediaRestartListener);
		const mediaStopListener = mqttReceiver.addOnMediaStopListener(onMediaStopListener);
		const mediaSeekListener = mqttReceiver.addOnMediaSeekListener(onMediaSeekListener);
		const mediaSyncListener = mqttReceiver.addOnMediaSyncListener(onMediaSyncListener);
		const mediaSubtitleListener = mqttReceiver.addOnSubTitleChange(onMediaSubtitleListener);
		const mediaAudioListener = mqttReceiver.addOnAudioChange(onMediaAudioListener);
		const pingListener = mqttReceiver.addOnPingReceivedListener(onPingListener);
		const pingBackListener = mqttReceiver.addOnPingBackReceivedListener(onPingBackListener);

		return () => {
			window.removeEventListener("beforeunload", unload);
			window.removeEventListener("popstate", handleBackButtonClick);
			mqttReceiver.deleteEventListener(MqttCommand.MediaPlay, mediaPlayListener);
			mqttReceiver.deleteEventListener(MqttCommand.MediaPause, mediaPauseListener);
			mqttReceiver.deleteEventListener(MqttCommand.MediaRestart, mediaRestartListener);
			mqttReceiver.deleteEventListener(MqttCommand.MediaStop, mediaStopListener);
			mqttReceiver.deleteEventListener(MqttCommand.MediaSeek, mediaSeekListener);
			mqttReceiver.deleteEventListener(MqttCommand.MediaSync, mediaSyncListener);
			mqttReceiver.deleteEventListener(MqttCommand.MediaSubtitle, mediaSubtitleListener);
			mqttReceiver.deleteEventListener(MqttCommand.MediaAudio, mediaAudioListener);
			mqttReceiver.deleteEventListener(MqttCommand.Ping, pingListener);
			mqttReceiver.deleteEventListener(MqttCommand.PingBack, pingBackListener);
		};
	}, []);

	useEffect(() => {
		if (!mqttDevices) {
			return;
		}

		const playbackChannels = mqttDevices
			.sort((a: any, b: any) => a?.screenName?.localeCompare(b?.screenName))
			.filter((val: any) => val?.body?.movieDetails)
			.map((curr: any) => {
				return curr;
			});

		screenHost = playbackChannels;

		AppLogger.log("currentDevices: ", screenHost);
	}, [mqttDevices]);

	useEffect(() => {
		const result = movieStoreValue.titleDetails.titles[parentProductId];
		const apiStatus = movieStoreValue.titleDetails.status;

		if (!result) {
			(async () => {
				const currentMetadata: any = await getMetadata(parentProductId);

				let portraitImage;
				if (currentMetadata) {
					portraitImage = currentMetadata.images
						?.filter((val: any) => val.width === 600 && val.height === 900)
						?.map((val: any) => val.url)[0];

					setPackshot(portraitImage);
				}
			})();
		}

		if (result?.metadata) {
			const portraitImage = result?.metadata.images
				?.filter((val: any) => val.width === 600 && val.height === 900)
				?.map((val: any) => val.url)[0];

			setPackshot(portraitImage);
		}

		if (apiStatus === "failed") {
			checkErrorNetwork();
		}
	}, [movieStoreValue]);

	useEffect(() => {
		if (carActivity === CarActivity.INMOTION && config != null) {
			setShowCarToast(true);
		}
	}, [carActivity, config]);

	/**
	 * Update Product ID
	 */
	useEffect(() => {
		parentProductIdRef.current = parentProductId;
	}, [parentProductId]);

	/**
	 * Handle errors in playback
	 */
	useEffect(() => {
		if (playbackErrorCode === 0) return;
		AppLogger.error("[PLAYBACK] playbackErrorCode", playbackErrorCode);
		switch (playbackErrorCode) {
			case 6007:
				setToastMessage(`${globalAny.language.error} ${playbackErrorCode}: ${globalAny.language.license_error}`);
				setType(ComponentTypeEnum.Secondary);
				setToastLabel(globalAny.language.ok);
				setShowToast(true);
				setTimeout(() => onGoBack(), 5000);
				break;
			case 1003:
				setToastMessage(`${globalAny.language.error} ${playbackErrorCode}: ${globalAny.language.connection_timeout}`);
				setType(ComponentTypeEnum.Secondary);
				setToastLabel(globalAny.language.ok);
				setShowToast(true);
				setTimeout(() => onGoBack(), 5000);
				break;
			case 1002:
				setToastMessage(`${globalAny.language.error} ${playbackErrorCode}: ${globalAny.language.network_error}`);
				setType(ComponentTypeEnum.Secondary);
				setShowToast(true);
				if (!config) setTimeout(() => onGoBack(), 5000);
				break;
			case 1:
				setToastMessage(`${globalAny.language.playback_error}`);
				setType(ComponentTypeEnum.Secondary);
				setToastLabel(globalAny.language.ok);
				setShowToast(true);
				setTimeout(() => onGoBack(), 5000);
				break;
			default:
				setToastMessage(`${globalAny.language.error} ${playbackErrorCode}`);
				setType(ComponentTypeEnum.Secondary);
				setToastLabel(globalAny.language.ok);
				setShowToast(true);
				setTimeout(() => onGoBack(), 5000);
				break;
		}
	}, [playbackErrorCode]);

	/**
	 * Make sure the shaka player reference is ready and then load the video
	 * Refresh parameter reminds us to load the new video if one ordered while playback is active
	 */
	useEffect(() => {
		reloadVideo();
	}, [refresh]);

	useEffect(() => {
		if (!dubCard) return;
		reloadVideo();
	}, [dubCard]);

	useEffect(() => {
		if (!refresh || !mqttDevices.length) return;

		(async () => {
			const currentMetadata: any = await getMetadata(parentProductId);
			let portraitImage;
			if (currentMetadata) {
				portraitImage = currentMetadata.images?.filter((val: any) => val.width === 600 && val.height === 900)?.map((val: any) => val.url)[0];

				setPackshot(portraitImage);
			}
		})();
	}, [refresh, mqttDevices]);

	useEffect(() => {
		
	}, [navigation]);

	return (
		<View style={styles.container}>
			{showModal && (
				<CustomizeDialogBox
					Header={globalAny.language.restore_default}
					Body={globalAny.language.modal_restore_body}
					ButtonTextRight={globalAny.language.cancel}
					ButtonTextLeft={globalAny.language.modal_accept}
					SingleButton={false}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Primary}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					onDismiss={() => {}}
					onPressRight={() => setShowModal(false)}
					onPressLeft={() => {
						restoreDefaultSettings();
					}}
				></CustomizeDialogBox>
			)}

			<PlayerLoader show={loading || refresh}></PlayerLoader>
			{config != null && !refresh && (
				<React.Suspense fallback={<View>{/* <Spinner show={true} isStyle={false}></Spinner> */}</View>}>
					<LazyPlayer
						ref={controllerRef}
						//@ts-ignore
						config={{ ...config, groupSyncColor, preview }}
						eventListeners={{
							onPlay: broadcastPlayPause,
							onPause: broadcastPlayPause,
							onSeek: onSeekButtonListener,
							onAudioChange: onAudioChangeListener,
							onSubtitleChange: onSubtitleChangeListener,
							onBuffer: onBufferListener,
							onBack: onGoBack,
							onRestart: onRestart,
							onScreenManager: onScreenManager,
							onWatchNow: onWatchNow,
							onHome: onGoHome,
							onProgress: onProgress,
							onShowModal: setShowModal,
							onInitialLoading: setLoading,
							onGetStats: onGetStats,
							onGetFiles: onGetFiles,
						}}
						externalEventHandler={PlayerEventHandlerExternal}
					/>
				</React.Suspense>
			)}

			{carActivity === CarActivity.INMOTION && config != null && (
				/* @ts-ignore */
				<StyledContainer>
					{/* @ts-ignore */}
					<PackshotContainer>
						<CardPackshot image={packshot}></CardPackshot>
					</PackshotContainer>
				</StyledContainer>
			)}

			{showStats && (
				<Nerds
					onPress={() => setShowStats(false)}
					playerVariant={playerVariant}
					playerStat={playerStats}
					videoStat={videoStat}
					bufferedInfo={bufferedInfo}
					manifestUrl={manifestUrl}
					alpha={alpha}
					audioFile={files?.getAudioFile}
					videoFile={files?.getVideoFile}
					playbackErrorCode={playbackErrorCode}
				/>
			)}

			<Toast
				visible={showCarToast}
				text={globalAny.language.car_in_motion_body}
				label={""}
				type={ComponentTypeEnum.Secondary}
				onDismissSnackBar={() => setShowCarToast(false)}
				onPress={() => {}}
			></Toast>

			<Toast
				visible={showOnline}
				text={globalAny.language.connection_restored}
				label={""}
				type={ComponentTypeEnum.Primary}
				onDismissSnackBar={() => setShowOnline(false)}
				onPress={() => {}}
			></Toast>

			<Toast visible={showToast} text={toastMessage} label={toastLabel} type={type} onDismissSnackBar={onDismissSnackBar} onPress={onGoBack} />
			<NerdButton onPress={showNerds} />
		</View>
	);
};

export default PlayerScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "black",
	},

	warningText: {
		color: "white",
		marginTop: "10px",
		textAlign: "center",
		fontWeight: "bold",
	},
});
