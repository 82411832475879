import SettingsContainer from "../../components/Layout/SettingsContainer";
import styled from "styled-components/native";
import { globalAny } from "../../utils/Utils";
import H4 from "../../components/Typography/H4";
import { palladium } from "../../StyleHelpers";
import H5 from "../../components/Typography/H5";
import { View } from "react-native";
import AndroidQr from "../../assets/Images/android_qr.png";
import IosQr from "../../assets/Images/ios_qr.png";
import Image from "../../components/Image/Image";
import GradientCodeComponent from "../../components/GenerateCodeComponent";
import { useEffect, useState } from "react";
import { signInCode } from "../../services/accountService";
import useToggleStore from "../../store/useToggle.store";

const StyledAddScreenContainer = styled.Text`
	padding-top: 30;
`;

const StyledTitle = styled.Text`
	margin-bottom: 16;
`;

const StyledTextContainer = styled.View`
	width: 30vw;
	margin-bottom: 40px;
`;

const StyledText = styled.Text`
	color: ${palladium};
`;

const StyledContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const SettingsAddScreen = () => {
	const [isGenerateCode, setIsGenerateCode] = useState(false);
	const [generateCode, setGenerateCode] = useState<any>();
	const [counter, setCounter] = useState(1);
	const [disabled, setDisabled] = useState(false);
	const [timer, setTimer] = useState(false);
	const isToggleQR = useToggleStore((state: any) => state.isToggleQR);

	const onGenerateCode = async () => {
		setDisabled(true);
		setIsGenerateCode(true);
		const setSignInCode: any = await signInCode();
		if (setSignInCode.code) {
			setTimer(true);
			setGenerateCode(setSignInCode.code);
			setDisabled(false);
			return;
		}
		setDisabled(false);
	};

	useEffect(() => {
		if (counter > 0 && timer) {
			setTimeout(() => setCounter(counter - 0.017), 1000);
		} else {
			setCounter(1);
			setTimer(false);
			setIsGenerateCode(false);
			setGenerateCode("");
		}
	}, [counter, timer]);

	const renderTitle = (title: string, desc: string, subDesc?: string) => {
		return (
			<View>
				{/* @ts-ignores */}
				<StyledTitle>
					<H4>{title}</H4>
				</StyledTitle>
				{/* @ts-ignores */}
				<StyledTextContainer>
					<H5 fontColor={palladium}>
						{/* @ts-ignores */}
						<StyledText>{desc}</StyledText>
						{/* @ts-ignores */}
						<StyledText>{subDesc}</StyledText>
					</H5>
				</StyledTextContainer>
			</View>
		);
	};

	return (
		// @ts-ignores
		<StyledAddScreenContainer>
			{/* @ts-ignores */}
			<SettingsContainer>
				{/* @ts-ignores */}
				<StyledContainer>
					{renderTitle(globalAny.language.sign_in_with_code, globalAny.language.sign_in_with_code_desc)}
					<View>
						<GradientCodeComponent
							title={isGenerateCode ? generateCode : globalAny.language.generate_code ?? ""}
							onPress={() => onGenerateCode()}
							hideButton={isGenerateCode}
							counter={counter}
							disabled={disabled}
							isMobile={false}
						/>
					</View>
				</StyledContainer>
			</SettingsContainer>
			{/* @ts-ignores */}
			{isToggleQR && (
				<SettingsContainer>
					{/* @ts-ignores */}
					<StyledContainer>
						{renderTitle(globalAny.language.download_app, globalAny.language.download_app_desc, " www.companionapp.ridevu.com")}
						<View style={{ paddingBottom: 30 }}>
							<Image source={{ uri: IosQr }} width={160} height={160} resizeMode="contain" />
						</View>
					</StyledContainer>
				</SettingsContainer>
			)}

			{isToggleQR && (
				<SettingsContainer>
					{/* @ts-ignores */}
					<StyledContainer>
						{renderTitle(globalAny.language.download_app_android, globalAny.language.download_app_desc, " www.companionapp.ridevu.com")}
						<View style={{ paddingBottom: 30 }}>
							<Image source={{ uri: AndroidQr }} width={160} height={160} resizeMode="contain" />
						</View>
					</StyledContainer>
				</SettingsContainer>
			)}
		</StyledAddScreenContainer>
	);
};

export default SettingsAddScreen;
