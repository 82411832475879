import { Dimensions, FlatList, View } from "react-native";
import PlaylistSlider from "../PlaylistSlider";
import PlaylistTitle from "../../components/PlaylistTitle";
import { useNavigation } from "@react-navigation/native";
import { routeAggregatePage, routeTitleDetails, routeVamAggregatePage, TitleDetailsScreenNavigationProp } from "../../Routes";
import useGenericContentStore from "../../store/genericContent.store";
import Banner from "../Banner";
import { useEffect, useState } from "react";
import HeroBanner from "../../screens/Home/HeroBanner";
import FooterBanner from "../../screens/Home/FooterBanner";
import { AsyncStorageKeys, CategoryTemplateTypes, CategoryTextTranslation } from "../../Types";
import { getCustomerPlaylist, getPlaylist, getPlaylistWithPage, getVamPlaylist } from "../../services/playlistService";
import { getFeaturedLists } from "../../services/apiMoviesContentService";
import { getItem } from "../../utils/Utils";
import CustomizeLoader from "../Loaders/CustomizeLoader";
import CardGridPlaylist from "../CardGridPlaylist";
import { AppLogger } from "../../utils/AppLogger";
import AggregatePageLoader from "../Loaders/AggregatePageLoader";

interface Props {
	location?: string;
	hideTitle?: boolean;
	isSeeMore?: boolean;
	categories?: any;
	transactionalFilters?: string;
	headerBanner?: any;
	footerBanner?: any;
	setIsMyLibraryEmpty?: any;
	isStudioAccess?: boolean;
	isAssignToScreen?: boolean;
	setIsShowLoader: any;
	setStartIndex: any;
	startIndex: number;
	isPreview?: boolean;
	setIsAggregate?: any;
	isHome?: boolean;
	setIsContinueWatching?: any;
}

const imageLimit = 15;
const appendCount = 1;
const defaultItem = [
	{
		id: 2,
		isLandscape: false,
		packshot: [1, 2, 3, 4, 5, 6, 7],
	},
];
const defaultItemLandscape = [
	{
		id: 1,
		isLandscape: true,
		packshot: [1, 2, 3, 4],
	},
];

const defaultCategoryType = {
	isHeroBanner: false,
	isBanner: false,
	isLandscape: false,
	isPortrait: false,
	isVAM: false,
};

let controller = new AbortController();
const pageSize = 24;

const Playlist = (props: Props) => {
	const navigationTitleScreen = useNavigation<TitleDetailsScreenNavigationProp>();
	const setAggregateTitle = useGenericContentStore((state: any) => state.setAggregateTitle);
	const [playlists, setPlaylists] = useState<any>([]);
	const [isShowFooter, setIsShowFooter] = useState(false);
	const [isShowHeader, setIsShowHeader] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [next, setNext] = useState(pageSize);
	const [isGrid, setIsGrid] = useState(false);
	const [gridSize, setGridSize] = useState<any>(6);
	const [gridPlaylist, setGridPlaylist] = useState<any>([]);
	const [searchLoader, setSearchLoader] = useState(false);
	const [windowWidth, setWindowWidth] = useState<any>();
	const [currentCategory, setCurrentCategory] = useState<any>();
	const [categoryType, setCategoryType] = useState<any>(defaultCategoryType);
	const [isShowAppendLoader, setIsShowAppendLoader] = useState(false);
	const navigation: any = useNavigation();
	let startIndex = props.startIndex;
	let setStartIndex = props.setStartIndex;

	const linkId = (id: any, item: any, length: number, title: string, isVAM: any) => {
		const limit = props?.isStudioAccess ? 7 : imageLimit;
		if (item.isBanner && item.products.length === 1) {
			const [product] = item.products;
			navigation.navigate(routeTitleDetails, { parentProductId: product.parentProductId });
			return;
		}

		if (length < limit) {
			return;
		}
		setAggregateTitle(title);
		navigation.navigate(isVAM ? routeVamAggregatePage : routeAggregatePage, isVAM ? { customListId: id } : { location: props.location, id });
	};

	/**
	 * Check if category template requires Landscape Packshot
	 * @param category - category from formatPlaylist
	 * @returns boolean
	 */
	const isCategoryLandscape = (category: any) =>
		category.categoryTemplateType === CategoryTemplateTypes.HKA || category.categoryTemplateType === CategoryTemplateTypes.VAM;

	/**
	 * heck if category template requires Portrait Packshot
	 * @param category - category from formatPlaylist
	 * @returns boolean
	 */
	const isCategoryPortrait = (category: any) =>
		category.categoryTemplateType === CategoryTemplateTypes.STD ||
		category.categoryTemplateType === CategoryTemplateTypes.ContinueWatching ||
		category.categoryTemplateType === CategoryTemplateTypes.Download;

	/**
	 * If isMyLibrary is true and props has setIsMyLibrary function
	 * setIsMyLibrary will set true if products.length is zero.
	 * @param isMyLibrary - if category from formatPlaylist is from my library
	 * @param products - products from formatPlaylist
	 * @returns
	 */
	const validateMyLibraryIsEmpty = (isMyLibrary: boolean, products: any) =>
		isMyLibrary && props?.setIsMyLibraryEmpty ? props.setIsMyLibraryEmpty(products?.length === 0) : false;

	/**
	 * If isContinueWatching is true and props has setIsContinueWatching function
	 * setIsContinueWatching will set true if there's product available.
	 * @param isContinueWatching - if category from formatPlaylist is from continue watching carousel
	 * @param products - products from formatPlaylist
	 * @returns
	 */
	const validateIsContinueWatching = (isContinueWatching: boolean, products: any) =>
		isContinueWatching && props?.setIsContinueWatching ? props.setIsContinueWatching(products?.length > 0) : false;

	/**
	 * Get Category Type if it will render as (HeroBanner, Banner, HKA, STD or VAM)
	 * @param category
	 * @returns
	 */
	const getCategoryType = (category: any) => {
		const isHeroBanner = category.categoryTemplateType === CategoryTemplateTypes.FeatureTemplate;
		const isBanner = category.categoryTemplateType === CategoryTemplateTypes.Banner;
		const isLandscape = isCategoryLandscape(category);
		const isPortrait = isCategoryPortrait(category);
		const isVAM = category.categoryTemplateType === CategoryTemplateTypes.VAM;
		const isGrid = category.categoryTemplateType === CategoryTemplateTypes.Grid;
		const isContinueWatching = category.categoryTemplateType === CategoryTemplateTypes.ContinueWatching;

		return {
			isHeroBanner,
			isBanner,
			isLandscape,
			isPortrait,
			isVAM,
			isGrid,
			isContinueWatching,
		};
	};

	/**
	 * Formatting playlist object to determine if categories will render as
	 * (HeroBanner, Banner, HKA, STD or VAM)
	 * @param category - category from props.categories
	 * @param playlist - playlist per category from fetchPlaylist
	 * @returns
	 */
	const formatPlaylist = (category: any, playlist: any) => {
		const { isHeroBanner, isBanner, isLandscape, isPortrait, isVAM, isGrid, isContinueWatching } = getCategoryType(category);

		let translatedTitle = category.categoryTextTranslations.find((translation: CategoryTextTranslation) => {
			if (translation.name.toLowerCase() == "title") return true;
		});

		let isMyLibrary = translatedTitle?.text === "My Library" || category.name === "My Library";
		let title = translatedTitle?.text ?? category.name;
		let products = isHeroBanner ? playlist.featureList : playlist.products || [];

		validateMyLibraryIsEmpty(isMyLibrary, products);
		validateIsContinueWatching(isContinueWatching, products);

		if (isContinueWatching) products = products.slice(0, 15);

		return {
			isLandscape: isLandscape,
			isPortrait: isPortrait,
			isProgressBar: false,
			playListCarousel: true,
			playlistId: category.playlistId || category.consumerPlaylistId,
			customListId: category.customListId,
			categoryName: category.name,
			title: title,
			rawTitle: category.name,
			isVam: isVAM,
			isBanner: isBanner,
			categoryImages: category?.categoryImages,
			uri: null,
			products: isVAM ? products.slice(0, 20) : products,
			id: category.id,
			isHeroBanner,
			isGrid,
			isContinueWatching,
		};
	};

	/**
	 * Fetch Playlist based on ID
	 * @param category - playlist Category
	 * @returns
	 */
	const fetchPlaylist = async (category: any, signal: any) => {
		let playlist;
		let formattedPlaylist;

		setCurrentCategory(category);

		if (category.playlistId) {
			playlist =
				category.categoryTemplateType === CategoryTemplateTypes.Grid
					? await getPlaylistWithPage(category.playlistId, false, pageSize, currentPage, props?.isPreview)
					: await getPlaylist(category.playlistId, false, signal, props?.isPreview);
		} else if (category.customListId) {
			playlist = await getVamPlaylist(category.customListId, 15, 1, signal);
		} else if (category.consumerPlaylistId) {
			if (props?.isPreview) return [];
			playlist = await getCustomerPlaylist(category.consumerPlaylistId, props.transactionalFilters, 15, 1, signal);
		} else {
			playlist = await getFeaturedLists(category.id, props.transactionalFilters, signal, props?.isPreview);
		}

		formattedPlaylist = formatPlaylist(category, playlist);

		return formattedPlaylist;
	};

	const onPress = (parentProductId: any) => {
		const defaultRoute = props?.isPreview ? { parentProductId, preview: true } : { parentProductId };
		props?.isPreview
			? navigation.navigate(routeTitleDetails, defaultRoute)
			: navigationTitleScreen.navigate(routeTitleDetails, { parentProductId: parentProductId });
	};

	/**
	 * Render HeroBanner component
	 * @param products - playlist/item from renderItem
	 * @returns
	 */
	const renderHeroBanner = ({ products }: any) => {
		return (
			<View style={{ paddingTop: 30, marginRight: props?.isPreview ? 100 : 0 }}>
				<HeroBanner heroBanner={products} isPreview={props?.isPreview}></HeroBanner>
			</View>
		);
	};

	const renderPlaylist = (ID: any, item: any) => {
		return (
			<>
				{!item?.isHeroBanner && !item.isBanner && !item.isGrid ? (
					<PlaylistSlider
						id={ID}
						title={item.title}
						products={props?.isStudioAccess || item?.isVam ? item.vamProducts : item.products}
						isLandscape={item.isLandscape}
						customListId={props?.isStudioAccess || item?.isVam ? ID : null}
						isStudioAccess={props?.isStudioAccess || item?.isVam}
						isAssignToScreen={props?.isAssignToScreen}
						isSeeMore={props?.isPreview || item?.isContinueWatching ? false : props.isSeeMore}
						seeMoreLength={props?.isStudioAccess ? 7 : imageLimit}
						isProgressBar={item.isProgressBar}
						location={props.location}
						isPreview={props?.isPreview}
						isHome={props?.isHome}
					/>
				) : (
					<></>
				)}
				{!item?.isHeroBanner && !item.isBanner && item.isGrid ? (
					<CardGridPlaylist
						isHome={props.isHome}
						data={gridPlaylist}
						parentProductId={ID}
						isAggregate={true}
						isPreview={props?.isPreview}
					/>
				) : (
					<></>
				)}
				{!item?.isHeroBanner && !item.isBanner && item.isGrid && searchLoader && (
					<View style={{ marginTop: 20 }}>
						<AggregatePageLoader isVam={false} slice={gridSize} />
					</View>
				)}
			</>
		);
	};

	/**
	 * Render playlist per categories
	 * @param item - playlist from fetchPlaylist
	 * @returns component
	 */
	const renderItem = ({ item }: any) => {
		if (!item?.products?.length) return <></>;
		const ID = props?.isStudioAccess ? item.customListId : item.id;

		if (item.isGrid) {
			setIsGrid(item.isGrid);
			!gridPlaylist.length && setGridPlaylist(item.products);
		}

		return (
			<>
				{item?.isHeroBanner ? renderHeroBanner(item) : <></>}
				{!item?.isHeroBanner && !props?.hideTitle && !item.isGrid ? (
					<View style={{ marginLeft: 147 }}>
						<PlaylistTitle
							title={item.title}
							isContinueWatching={item.isContinueWatching}
							onPress={() => !props?.isPreview && linkId(ID, item, item.products.length, item.title, props?.isStudioAccess)}
						/>
					</View>
				) : (
					<></>
				)}
				{renderPlaylist(ID, item)}
				{!item?.isHeroBanner && item.isBanner ? (
					<View style={{ marginRight: props?.isPreview ? 100 : 0 }}>
						<Banner item={item} onPress={(product) => onPress(product)} />
					</View>
				) : (
					<></>
				)}
			</>
		);
	};

	const onEndReachedGrid = async () => {
		if (next > gridPlaylist?.length) {
			setSearchLoader(false);
			return;
		}
		const nextPageNumber = currentPage + 1;
		setCurrentPage(nextPageNumber);

		try {
			const page = await getPlaylistWithPage(currentCategory.playlistId, false, pageSize, nextPageNumber);

			const nextPlaylist = formatPlaylist(currentCategory, page);
			const [nextPageResults] = getItem([nextPlaylist]);
			if (nextPageResults) {
				setGridPlaylist((prevGridPlaylist: any) => prevGridPlaylist.concat(nextPageResults.products));
				setNext((prevNext: any) => prevNext + pageSize);
			}
		} catch (err) {
			AppLogger.log(err);
		} finally {
			setSearchLoader(false);
		}
	};

	/**
	 * Lazy load other playlist
	 */
	const onEndReached = () => {
		controller = new AbortController();

		if (isGrid) {
			setSearchLoader(true);
			onEndReachedGrid();
			return;
		}

		if (isShowAppendLoader) return;

		if (startIndex > props.categories.length) {
			setIsShowFooter(true);
			return;
		}

		const categories = props.categories.slice(startIndex, startIndex + appendCount);
		const [firstCategory] = categories.slice();

		firstCategory && setCategoryType(getCategoryType(firstCategory));
		setIsShowAppendLoader(true);

		(async () => {
			const products = await Promise.all(categories.map(async (category: any) => await fetchPlaylist(category, controller.signal)));

			setIsShowAppendLoader(false);
			setPlaylists((prevPlaylists: any) => prevPlaylists.concat(getItem(products)));
			setStartIndex(startIndex + appendCount);
		})();
	};

	const renderAppendLoader = () => (
		<CustomizeLoader
			show={isShowAppendLoader}
			isPlaylist={categoryType?.isLandscape || categoryType?.isPortrait || categoryType?.isVAM}
			isBanner={categoryType?.isBanner}
			isHeroBanner={categoryType?.isHeroBanner}
			isVAM={categoryType.isVAM}
			data={categoryType?.isPortrait ? defaultItem : defaultItemLandscape}
			marginTop={5}
			isPreview={props?.isPreview}
		/>
	);

	/**
	 * if isShowFooter is true it will return the FooterBanner
	 * if not it will return the shimmer loader
	 * @returns footerBanner from props or loader
	 */
	const renderFooter = () => (isShowFooter && props?.footerBanner ? <FooterBanner banner={props.footerBanner} /> : renderAppendLoader());

	/**
	 * Render header Banner
	 * @returns headerBanner from props
	 */
	const renderHeader = () => (isShowHeader && props?.headerBanner ? props.headerBanner : <></>);

	/**
	 *
	 * @returns style for Playlist Container
	 */
	const getPlaylistStyle = () => {
		if (props?.isStudioAccess) {
			return { paddingTop: 112, paddingBottom: 121, height: "100vh" };
		}

		return { paddingTop: 112, paddingBottom: 30, height: "100vh" };
	};

	const calculateGridItemsByWindowWidth = (width: any) => {
		return onPortraitSize(width);
	};

	const onPortraitSize = (width: number) => {
		if (width <= 1659) return 4;
		return 6;
	};

	/**
	 * useEffect to detect resize on window
	 */
	useEffect(() => {
		const resizeHandler = () => {
			setWindowWidth(Dimensions.get("window").width);
			setGridSize(calculateGridItemsByWindowWidth(Dimensions.get("window").width));
		};
		resizeHandler();

		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [windowWidth]);

	useEffect(() => {
		controller = new AbortController();
		if (!props.categories.length) return;
		const categories = props.categories.slice(0, startIndex);

		(async () => {
			const products: any = await Promise.all(categories.map(async (category: any) => await fetchPlaylist(category, controller.signal)));
			setPlaylists(getItem(products));

			props?.setIsShowLoader(false);
			if (props?.isPreview) {
				props?.setIsAggregate(true);
			}
			setIsShowHeader(props?.headerBanner);
		})();

		return () => {
			setPlaylists([]);
			props?.setIsShowLoader(true);
			if (props?.isPreview) props?.setIsAggregate(false);
		};
	}, [props.categories]);

	return (
		<FlatList
			data={playlists}
			renderItem={renderItem}
			style={getPlaylistStyle()}
			keyExtractor={(item) => item.id}
			onEndReached={onEndReached}
			onEndReachedThreshold={0.8}
			ListHeaderComponent={renderHeader}
			ListFooterComponent={renderFooter}
			ListHeaderComponentStyle={{}}
			decelerationRate={"normal"}
			contentContainerStyle={{
				paddingBottom: props?.isPreview ? 120 : 0,
			}}
		/>
	);
};

export default Playlist;
