import { useNavigation } from "@react-navigation/native";
import { routeTitleDetails, VamScreenManagerNavigationProp, routeVamScreenManager, routeVamPlayer } from "../../Routes";
import { mid_blue, spacing_m, spacing_xs, white_smoke } from "../../StyleHelpers";
import { StyleSheet } from "react-native";
import { ProgressBar } from "react-native-paper";
import styled, { css } from "styled-components/native";
import { getMetadata } from "../../services/metadataService";
import { AsyncStorageKeys, Pages, direction } from "../../Types";
import Image from "../Image/Image";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import playIcon from "../../assets/Icons/Play-Clips.svg";
import lockIcon from "../../assets/Icons/lock_icon.svg";
import useGenericContentStore from "../../store/genericContent.store";
import { useEffect, useMemo, useState } from "react";
import { calculateImageWidth, convertDuration } from "../../utils/Utils";
import { getProgressVideo } from "../../services/progressVideoService";
import { v4 as uuidv4 } from "uuid";
import useMqttStore from "../../store/useMqtt.store";

const landscapeWidth = "21.56vw";
interface Props {
	productId?: any;
	vamId?: any;
	image?: any;
	title?: string;
	isClips?: boolean;
	isRedeem?: boolean;
	hideBorder?: boolean;
	isLandscape?: boolean;
	isProgressBar?: boolean;
	streamProgress?: number;
	isStudioAccess?: boolean;
	isAssignToScreen?: boolean;
	isVamAggregate?: boolean;
	isHome?: boolean;
	vamStatus?: number;
	disabled?: boolean;
	isPreview?: boolean;
	backupImage?: any;
	customListId?: any;
}

const StyledProgress = styled.View<Props>`
	border-radius: 16px;
	overflow: hidden;
	z-index: -1;
	${(props) =>
		props.isLandscape &&
		css`
			width: 100%;
			height: auto;
			aspect-ratio: 16 / 9;
		`}

	${(props) =>
		!props.isLandscape &&
		css`
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 3;
		`}
`;

const StyledProgressBar = styled.View`
	z-index: 1;
	bottom: 10px;
	width: 100%;
	padding: 0 20px 10px 20px;
	position: absolute;
`;

const StyledTitle = styled.View`
	width: ${landscapeWidth};
	margin-top: ${spacing_xs};
	margin-bottom: ${spacing_m};
`;
const StyledTitleText = styled.Text`
	color: white;
	font-size: 21px;
`;

const StyledIconContainer = styled.View`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CardPackshot = (props: Props) => {
	const navigation = useNavigation<any>();
	const navigationVamScreenManager = useNavigation<VamScreenManagerNavigationProp>();
	const setVamDetails = useGenericContentStore((state: any) => state.setVamDetails);
	const setMovieTitle = useGenericContentStore((state: any) => state.setMovieTitle);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const isRenderHome = useGenericContentStore((state: any) => state.isRenderHome);
	const [borderEvent, setBorderEvent] = useState(false);
	const [currentProgress, setCurrentProgress] = useState(0);
	const setPlaySessionId = useMqttStore((state: any) => state.setPlaySessionId);

	/**
	 * get page to navigate
	 * @param isVamAggregate
	 * @param isClips
	 */
	const getNavigatePage = (isVamAggregate: any, isClips: any) => {
		if (isVamAggregate) return Pages.vamAggregatePage;

		return isClips ? Pages.main : Pages.shortClips;
	};

	const onPress = async () => {
		const { productId: parentProductId, vamId, image, title, isAssignToScreen, isRedeem, isStudioAccess, isClips, isVamAggregate, customListId } = props;

		if (vamId && (isRedeem || isStudioAccess)) {
			const metadata: any = await getMetadata(parentProductId);
			const movieTitle = metadata?.title ? `${metadata.title} - ${title}` : title;
			const isTDP = !isClips || isStudioAccess ? direction.studio : direction.tdp;
			const handleAssignToScreen = (parentProductId:any, vamId:any, isTDP:any, customListId:any) => {
				navigationVamScreenManager.push(routeVamScreenManager, {
				  location: Pages.assignScreen,
				  parentProductId,
				  vamId,
				  redirectTo: isTDP,
				  customListId: isVamAggregate ? customListId : "",
				});
			  };

			setMovieTitle(movieTitle);
			setVamDetails({ parentProductId, vamId, image, title: movieTitle });
			setNavigateEvent(getNavigatePage(isVamAggregate, isClips));
			if (isAssignToScreen) {
				handleAssignToScreen(parentProductId,vamId,isTDP,customListId);
				return;
			}

			const playSessionId = uuidv4();
			setPlaySessionId(playSessionId);
			localStorage.setItem(AsyncStorageKeys.playSessionId, playSessionId);
			navigation.push(routeVamPlayer, { parentProductId, vamId, location: direction.main, redirectTo: isTDP });
			return;
		}

		if (props?.isHome) setNavigateEvent("");

		if (parentProductId && !isStudioAccess) {
			setSelectedTabs(0);
			navigation.push(routeTitleDetails, props?.isPreview ? { parentProductId, preview: true } : { parentProductId });
		}
	};

	const renderProgress = () => {
		if ((!props.isLandscape && !props.isClips) || props.isLandscape || props.isClips) {
			return (
				//@ts-ignore
				<StyledProgress isLandscape={props.isLandscape}>
					<ImgProgressBar />
				</StyledProgress>
			);
		}
	};

	const renderTitle = () => {
		if (props.isClips || props.isStudioAccess) {
			return (
				// @ts-ignore
				<StyledTitle>
					{/* @ts-ignore */}
					<StyledTitleText>{props.title}</StyledTitleText>
				</StyledTitle>
			);
		}

		return <></>;
	};

	const getContainerStyle = () => (props.isClips ? styles.containerClips : styles.container);

	const getPackshotImage = () => {
		return { uri: `${props.image ?? props.backupImage}?width=${calculateImageWidth(props?.isLandscape, false)}&disablewebp=false` };
	};

	const getPackshotStyle = () => {
		if (props.isClips) return styles.landscape;

		return props.isLandscape ? styles.container : styles.portrait;
	};

	const getPackshotBorder = () => {
		if (props.hideBorder) return { borderRadius: 16, position: "absolute" };

		return {
			borderRadius: 16,
			position: "absolute",
			transition: borderEvent ? "outline-color 0.6s ease" : "",
			outline: `2px solid ${borderEvent ? white_smoke : "rgba(255, 255, 255, 0.30)"}`,
			outlineOffset: -2,
		};
	};

	const getVamIcon = () => {
		if (props.isRedeem) return { uri: playIcon };

		if (props.isStudioAccess) return { uri: playIcon };

		return { uri: props.vamStatus === 2 ? lockIcon : playIcon };
	};

	const renderPackshot = useMemo(
		() => (
			<>
				<TouchableOpacity
					disabled={props.disabled || props.vamStatus === 2}
					onPress={() => onPress()}
					style={getContainerStyle()}
					onMouseEnter={() => setBorderEvent(true)}
					onMouseLeave={() => setBorderEvent(false)}
				>
					{renderProgress()}
					<Image source={getPackshotImage()} resizeMode="contain" style={[getPackshotStyle(), getPackshotBorder()]} />

					{props.vamId ? (
						// @ts-ignore
						<StyledIconContainer contentId={props.vamId}>
							<Image source={getVamIcon()} style={styles.imageIcon} resizeMode="contain" />
						</StyledIconContainer>
					) : (
						<></>
					)}

					{props.isProgressBar ? (
						// @ts-ignore
						<StyledProgressBar>
							<ProgressBar progress={currentProgress ?? 0} color={mid_blue} style={styles.progressBar} />
						</StyledProgressBar>
					) : (
						<></>
					)}
				</TouchableOpacity>
				{renderTitle()}
			</>
		),
		[currentProgress, props.isAssignToScreen, isRenderHome]
	);

	useEffect(() => {
		const controller = new AbortController();
		if (props.isProgressBar) {
			(async () => {
				const metadata: any = await getMetadata(props.productId, controller.signal);
				const streamDetails: any = await getProgressVideo(props.productId, controller.signal);
				const currentProgress: any = Number(streamDetails?.streamProgress) / (convertDuration(metadata?.duration) * 1000) || 0;
				setCurrentProgress(currentProgress);
			})();
		}

		return () => {
			setCurrentProgress(0);
		};
	}, []);

	return renderPackshot;
};

export default CardPackshot;

const styles = StyleSheet.create({
	container: {
		width: "100%",
		height: "100%",
	},
	containerClips: {
		width: "100%",
	},
	landscape: {
		width: landscapeWidth,
		height: "auto",
		aspectRatio: 16 / 9,
	},
	portrait: {
		width: "100%",
		height: "auto",
		aspectRatio: 2 / 3,
	},
	imageIcon: {
		width: 56,
		height: 56,
		position: "absolute",
		backgroundColor: " rgba(0, 0, 0, .5)",
		borderRadius: 32,
	},
	progressBar: {
		borderRadius: 10,
		height: 8,
		backgroundColor: "rgba(255,255,255, .5)",
	},
});
